import client from "./client";

interface addUserPayload {
id : number;
permissions : any

}


export const getNavItems = async (token: string) =>
    await client.get("/employees/get-nav",{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  export const getAddUserPermission = async (payload: addUserPayload,token: string) =>
      await client.post("/employees/add-role-permissions",payload,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });



      export const getRoleList = async (token: string,page:number,limit:number) =>
        await client.get(`/employees/get-allroles?page=${page}&limit=${limit}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });