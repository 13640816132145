import React, { ReactNode } from "react";
import { Box, Typography, Avatar } from "@mui/material";
import BorderText from "../Label/BorderText";
import Icons from "../Icons";
import BugStatus from "../Dropdown/BugStatus";
import Cookies from "js-cookie";
import ToastMessage from "../ToastMessage";
import ImageViewer from "./ImageViewer";

interface detailProps {
  bugDetData: {
    id: number;
    bug_title: string;
    bug_description: string;
    speed: string;
    browser_info: string;
    attachment: string;
    file_id: number;
    file_name: string;
    file_type_id: number;
    file_type_name: string;
    area_of_study_id: number;
    area_of_study_name: string;
    subject_id: number;
    subject_name: string;
    topic_id: number;
    topic_name: string;
    sub_grp_id: number;
    sub_grp_name: string;
    sub_grp_code: string;
    student_id: number;
    reported_by: string;
    record_status: string;
    createdAt: string;
    updatedAt: string;
  };
  bugStatus?: string;
  setBugStatus?: React.Dispatch<React.SetStateAction<string>>;
  bugDeviceDetail: {
    device: ReactNode;
    os: ReactNode;
    browser: string;
    browserVersion: string;
  };
  onBack?: () => void;
  goBack?: boolean;
}
const tbStyle = {
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center",
  paddingLeft: "32px",
};
const BugDetail = ({
  onBack,
  bugDetData,
  bugDeviceDetail,
  bugStatus,
  setBugStatus,
  goBack,
}: detailProps) => {
  const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;
  const token = verifyAccess?.token ?? null;
  const handleButtonClick = (value: string) => {
    console.log(`Button clicked with value: ${value}`);
    setBugStatus && setBugStatus(value);
  };
  const [toast, setToast] = React.useState<{
    type: string;
    message: string;
  } | null>(null);
  const dateObj = new Date(bugDetData.createdAt);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const month = monthNames[dateObj.getMonth()];
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  let hours = dateObj.getHours();
  const minutes = (dateObj.getMinutes() < 10 ? "0" : "") + dateObj.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  const formattedDate = `${month} ${day}, ${year} ${hours}:${minutes}${ampm}`;

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {!goBack && (
          <Box
            onClick={onBack}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={"4px"}
            sx={{ cursor: "pointer", paddingLeft: "22px" }}
          >
            <Icons name="chevronLeft" fill="#8E8D8A" />
            <BorderText text={"Back"} />
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            alignItems: "center",
            paddingLeft: "30px",
          }}
        >
          <Icons width={18} height={18} name="recording" fill="#8E8D8A" />
          <Typography variant="bodyLg" color={"#2B3258"}>
            {bugDetData.file_name}
          </Typography>
        </Box>
        <Box sx={tbStyle}>
          <Typography
            width={"30%"}
            maxWidth={"105px"}
            variant="bodySm"
            color={"#696969"}
          >
            Uploaded by
          </Typography>

          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={"4px"}
          >
            <Avatar
              sx={{
                width: 20,
                height: 20,
                fontSize: "12px",
                bgcolor: "#F4F6FF",
                color: "#000000",
                textTransform: "capitalize",
              }}
            >
              {verifyAccess.responseData.first_name.charAt(0)}
            </Avatar>
            <Typography
              variant="bodyLg"
              color={"#000000"}
              sx={{ textTransform: "capitalize" }}
            >
              {verifyAccess.responseData.first_name}
            </Typography>
            <Typography variant="bodySm" color={"#696969"} ml={"4px"}>
              {formattedDate}
            </Typography>
          </Box>
        </Box>
        <Box sx={tbStyle}>
          <Typography
            width={"30%"}
            maxWidth={"105px"}
            variant="bodySm"
            color={"#696969"}
          >
            Status
          </Typography>
          <BugStatus
            status={bugStatus?.toLowerCase() || ""}
            type="bugs"
            setToast={setToast}
            token={token}
            onClickCallback={handleButtonClick}
            fileId={bugDetData.id}
            dateUpdate={bugDetData.updatedAt}
          />
        </Box>
        <Box sx={tbStyle}>
          <Typography
            width={"30%"}
            maxWidth={"105px"}
            variant="bodySm"
            color={"#696969"}
          >
            Reported by
          </Typography>
          <Typography
            variant="bodySm"
            color={"#333"}
            sx={{ textTransform: "capitalize" }}
          >
            {bugDetData.reported_by}
          </Typography>
        </Box>
        <Box sx={tbStyle}>
          <Typography
            width={"30%"}
            maxWidth={"105px"}
            variant="bodySm"
            color={"#696969"}
          >
            Title
          </Typography>
          <Typography
            variant="bodySmBold"
            color={"#333"}
            sx={{ textTransform: "capitalize" }}
          >
            {bugDetData.bug_title}
          </Typography>
        </Box>
        <Box sx={{ ...tbStyle, alignItems: "flex-start" }}>
          <Typography
            width={"30%"}
            maxWidth={"105px"}
            variant="bodySm"
            color={"#696969"}
            sx={{ paddingLeft: "0px" }}
          >
            Description
          </Typography>
          <Typography
            width={"70%"}
            variant="bodySm"
            color={"#333"}
            flexWrap={"wrap"}
          >
            {bugDetData.bug_description}
          </Typography>
        </Box>

        <Box sx={{ ...tbStyle, alignItems: "flex-start" }}>
          <Typography
            width={"30%"}
            maxWidth={"105px"}
            variant="bodySm"
            color={"#696969"}
            sx={{ paddingLeft: "0px" }}
          >
            Internet speed
          </Typography>
          <Typography
            width={"70%"}
            variant="bodySm"
            color={"#333"}
            flexWrap={"wrap"}
          >
            {bugDetData.speed}
          </Typography>
        </Box>
        <Box sx={{ ...tbStyle, alignItems: "flex-start" }}>
          <Typography
            width={"30%"}
            maxWidth={"105px"}
            variant="bodySm"
            color={"#696969"}
            sx={{ paddingLeft: "0px" }}
          >
            Browser
          </Typography>
          <Typography
            width={"70%"}
            variant="bodySm"
            color={"#333"}
            flexWrap={"wrap"}
          >
            {bugDeviceDetail.browser}
          </Typography>
        </Box>
        <Box sx={{ ...tbStyle, alignItems: "flex-start" }}>
          <Typography
            width={"30%"}
            maxWidth={"105px"}
            variant="bodySm"
            color={"#696969"}
            sx={{ paddingLeft: "0px" }}
          >
            Browser Version
          </Typography>
          <Typography
            width={"70%"}
            variant="bodySm"
            color={"#333"}
            flexWrap={"wrap"}
          >
            {bugDetData.browser_info}
          </Typography>
        </Box>
        <Box sx={{ ...tbStyle, alignItems: "flex-start" }}>
          <Typography
            width={"30%"}
            maxWidth={"105px"}
            variant="bodySm"
            color={"#696969"}
            sx={{ paddingLeft: "0px" }}
          >
            Device
          </Typography>
          <Typography
            width={"70%"}
            variant="bodySm"
            color={"#333"}
            flexWrap={"wrap"}
          >
            {bugDeviceDetail.device}
          </Typography>
        </Box>
        <Box sx={{ ...tbStyle, alignItems: "flex-start" }}>
          <Typography
            width={"30%"}
            maxWidth={"105px"}
            variant="bodySm"
            color={"#696969"}
            sx={{ paddingLeft: "0px" }}
          >
            OS Type
          </Typography>
          <Typography
            width={"70%"}
            variant="bodySm"
            color={"#333"}
            flexWrap={"wrap"}
          >
            {bugDeviceDetail.os}
          </Typography>
        </Box>

      </Box>
      {bugDetData.attachment.length > 0 && (
        <Box
          sx={{
            width: "100%",
            height: "300px",
            marginTop: "12px",
          }}
        >
          <ImageViewer file={bugDetData.attachment} />
        </Box>
      )}
      {toast && (
        <ToastMessage
          type={toast.type as "success" | "error"}
          message={toast.message}
          onClose={() => setToast(null)}
          style={{
            position: "abosolute",
            top: "90px",
            textTransform: "capitalize",
          }}
        />
      )}
    </Box>
  );
};

export default BugDetail;
