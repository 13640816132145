import {
  Box,
  Typography,
  Avatar,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextareaAutosize,
} from "@mui/material";
import React from "react";
import Icons from "../Icons";
import RightDrawer from "../Drawer";
import FilesPreview from "../Files/FilesPreview";
import StatusText from "../Label/StatusText";
import ToastMessage from "../ToastMessage";
import Cookies from "js-cookie";
import {
  // fileStautusUpdate,
  recordingTimestamp,
  // viewFileDetails,
} from "../../services/api/fileLibrary";
import VideoPreview from "./VideoPreview";
import moment from "moment";
import BugDetail from "../BugReport/BugDetail";
import { viewBugDetail } from "../../services/api/dashboard";
import { convertToTranscript } from "../../utils/utilsfuns";
import { fileRecStautusUpdate, viewRecordingFileDetails } from "../../services/api/classLibrary";

type RowData = {
  id: number;
  file_password: string;
  uploaded_by: string;
  file_name: string;
  file_url: string;
  file_description: string;
  file_status: string;
  publish_status: string;
  version_history: any[];
  transcript_path: string;
  transcripts: string;
  upload_timestamp: string;
  publish_timestamp: string;
  ext_type: string;
  bug_list: any[];
  resolved_bug_count: number;
  unresolved_bug_count: number;
};

interface BugDetailState {
  id: number;
  bug_title: string;
  bug_description: string;
  browser_info: string;
  speed: string;
  attachment: string;
  file_id: number;
  file_name: string;
  file_type_id: number;
  file_type_name: string;
  area_of_study_id: number;
  area_of_study_name: string;
  subject_id: number;
  subject_name: string;
  topic_id: number;
  topic_name: string;
  sub_grp_id: number;
  sub_grp_name: string;
  sub_grp_code: string;
  student_id: number;
  reported_by: string;
  record_status: string;
  createdAt: string;
  updatedAt: string;
}

const tbStyle = {
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center",
};

const FileDetail: React.FC<{
  drawer: boolean;
  isEditing: boolean;
  setDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRowData: number | undefined;
}> = ({ drawer, setDrawer, selectedRowData, setIsEditing, isEditing }) => {
  const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;
  const [showFullDescription, setShowFullDescription] = React.useState(false);
  const [fileData, setFileData] = React.useState<RowData | undefined>({
    id: 0,
    resolved_bug_count: 0,
    unresolved_bug_count: 0,
    file_password: "",
    uploaded_by: "",
    file_name: "",
    file_url: "",
    file_description: "",
    file_status: "",
    publish_status: "",
    transcript_path: "",
    transcripts: "",
    version_history: [],
    upload_timestamp: "",
    publish_timestamp: "",
    ext_type: "",
    bug_list: [],
  });
  const [bugDetail, setBugDetail] = React.useState<BugDetailState>({
    id: 0,
    bug_title: "",
    bug_description: "",
    browser_info:"",
    speed: "",
    attachment: "",
    file_id: 0,
    file_name: "",
    file_type_id: 0,
    file_type_name: "",
    area_of_study_id: 0,
    area_of_study_name: "",
    subject_id: 0,
    subject_name: "",
    topic_id: 0,
    topic_name: "",
    sub_grp_id: 0,
    sub_grp_name: "",
    sub_grp_code: "",
    student_id: 0,
    reported_by: "",
    record_status: "",
    createdAt: "",
    updatedAt: "",
  });
  const [recStamp, setRecStamp] = React.useState("");
  const isVideo = fileData?.file_name.endsWith(".mp4");
  const [description, setDescription] = React.useState<string>(
    fileData?.file_description || ""
  );
  const [bugDeta, setBugDeta] = React.useState(false);
  const [toast, setToast] = React.useState<{
    type: string;
    message: string;
  } | null>(null);

  const fetchFileDetail = async () => {
    try {
      const id = selectedRowData ?? 0;
      const response = await viewRecordingFileDetails(verifyAccess.token, id);
      console.log("file detail", response.data.data[0]);
      setFileData(response.data.data[0]);
      const textTranscript = convertToTranscript(response.data.data[0].transcripts)
      setRecStamp(textTranscript);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  console.log("anilll", fileData?.upload_timestamp);
  React.useEffect(() => {
    fetchFileDetail();
  }, [selectedRowData]);

  const handleShowMoreClick = () => {
    setShowFullDescription(!showFullDescription);
  };

  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);

  const handleEditClick = () => {
    setIsEditing && setIsEditing(true);

  };

  const fetchBugDetailData = async (id: number) => {
    try {
      const response = await viewBugDetail(verifyAccess.token, id);
      setBugDetail(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSaveClick = async () => {
    try {
      const id = selectedRowData ?? 0;
      const payload = {
        fileId: [id],
        fileDescription: description,
      };
      const response = await fileRecStautusUpdate(payload, verifyAccess.token);
      console.log("file detail", response.data);
      if (response && response.data) {
        setIsEditing && setIsEditing(false);
        setToast({
          type: "success",
          message: "Description is updated successfully",
        });
      } else {
        const errorMessage =
          response && response.data.message
            ? response.data.message
            : "An error occured";
        setToast &&
          setToast({
            type: "error",
            message: errorMessage,
          });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleRecordTimestamp = async () => {
    try {
      const fileId = selectedRowData ?? 0;
      const response = await recordingTimestamp(
        recStamp,
        fileId,
        verifyAccess.token
      );
      if (response && response.data) {
        setToast({
          type: "success",
          message: "Recording timestamp is updated successfully",
        });
      } else {
        const errorMessage =
          response && response.data.message
            ? response.data.message
            : "An error occured";
        setToast &&
          setToast({
            type: "error",
            message: errorMessage,
          });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCancelEditClick = () => {
    setIsEditing && setIsEditing(false);
  };

  React.useEffect(() => {
    if (isEditing && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [isEditing]);

  console.log("ext_type", fileData);

  const showBugDetail = (id: number) => {
    setDrawer(false);
    setIsEditing && setIsEditing(false);
    setBugDeta(true);
    fetchBugDetailData(id);
  };

  const handleViewFile = () => {
    setBugDeta(false)
    setDrawer(true);
  };
  const setEmptyOnClose = () => {
    setFileData({
      id: 0,
      resolved_bug_count: 0,
      unresolved_bug_count: 0,
      file_password: "",
      uploaded_by: "",
      file_name: "",
      file_url: "",
      file_description: "",
      file_status: "",
      publish_status: "",
      transcript_path: "",
      transcripts: "",
      version_history: [],
      upload_timestamp: "",
      publish_timestamp: "",
      ext_type: "",
      bug_list: [],
    });
    setDrawer(false);
    setIsEditing && setIsEditing(false);
  };

  return (
    <>
      <RightDrawer
        open={drawer}
        handleClose={() => {
          setEmptyOnClose()
        }}
        title="File Details"
        extra={"1"}
      >
        <Box sx={{ position: "relative" }}>
          {toast && (
            <ToastMessage
              type={toast.type as "success" | "error"}
              message={toast.message}
              onClose={() => setToast(null)}
              style={{
                position: "sticky",
                top: "0px",
                left: "70%",
                transform: "translateX(-15%)",
              }}
            />
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              padding: "12px 12px 12px 24px",
            }}
          >
            {fileData?.bug_list?.length !== 0 && (
              <Box
                sx={{
                  // display: "flex",
                  display:"none",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #C61341",
                  borderRadius: "4px",
                  padding: "12px",
                  background: "#FFEEF2",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Icons width={18} height={20} name="bugIcon" fill="#C61341" />
                  <Typography
                    sx={{
                      marginLeft: "8px",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    {fileData?.unresolved_bug_count} Bugs reported
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    borderBottom: "2px solid #FFA737",
                    cursor: "pointer",
                  }}
                  onClick={() => showBugDetail(fileData?.bug_list[0].id)}
                >
                  Resolve
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
              }}
            >
              {fileData?.ext_type === "pdf" ? (
                <Icons width={18} height={18} name="pdfImage" fill="#8E8D8A" />
              ) : (
                <Icons width={18} height={18} name="recording" fill="#8E8D8A" />
              )}
              <Typography variant="bodyLg" color={"#2B3258"}>
                {fileData?.file_name}
              </Typography>
            </Box>
            <Box sx={tbStyle}>
              <Typography
                width={"30%"}
                maxWidth={"105px"}
                variant="bodySm"
                color={"#696969"}
              >
                Uploaded by
              </Typography>

              <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                gap={"4px"}
              >
                <Avatar
                  sx={{
                    width: 20,
                    height: 20,
                    fontSize: "12px",
                    bgcolor: "#F4F6FF",
                    color: "#000000",
                  }}
                >
                  {fileData?.uploaded_by?.charAt(0).toUpperCase()}
                </Avatar>
                <Typography
                  variant="bodyLg"
                  color={"#000000"}
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  {fileData?.uploaded_by}
                </Typography>
                <Typography variant="bodySm" color={"#696969"} ml={"4px"}>
                  {fileData?.upload_timestamp
                    ? moment(fileData?.upload_timestamp).format("MMMM Do YYYY, h:mm a")
                    : "--"}
                </Typography>
              </Box>
            </Box>
            <Box sx={tbStyle}>
              <Typography
                width={"30%"}
                maxWidth={"105px"}
                variant="bodySm"
                color={"#696969"}
              >
                File Status
              </Typography>
              <StatusText status={fileData?.publish_status||""} type="published" />
              <Typography variant="bodySm" color={"#696969"} ml={"4px"}>
                {fileData?.publish_timestamp
                  ? moment(fileData.publish_timestamp).format("MMMM Do YYYY, h:mm a")
                  : "--"}
              </Typography>
            </Box>
            <Box sx={tbStyle}>
              <Typography
                width={"30%"}
                maxWidth={"105px"}
                variant="bodySm"
                color={"#696969"}
              >
                Status
              </Typography>
              {fileData?.file_status ? <StatusText status={fileData?.file_status || ""} type="status" /> : "--"}
            </Box>
            <Box sx={tbStyle}>
              <Typography
                width={"30%"}
                maxWidth={"105px"}
                variant="bodySm"
                color={"#696969"}
              >
                File password
              </Typography>
              <Typography
                sx={{ fontSize: "14px", fontWeight: "400" }}
                color={"#333"}
              >
                {fileData?.file_password}
              </Typography>
            </Box>
            <Box sx={{ ...tbStyle, alignItems: "flex-start" }}>
              <Typography
                width={"30%"}
                maxWidth={"105px"}
                variant="bodySm"
                color={"#696969"}
              >
                Description
              </Typography>
              {isEditing ? (
                <Box
                  sx={{
                    "&.MuiBox-root": {
                      width: "100%",
                    },
                    "&.MuiBox-root textarea": {
                      width: "100%",
                      borderradius: "8px",
                      "&:focus-visible": {
                        outline: "none",
                        background: "#FFF8EC",
                        borderColor: "#FFA737",
                        borderradius: "8px",
                      },
                    },
                  }}
                >
                  <TextareaAutosize
                    value={description}
                    defaultValue={fileData?.file_description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      padding: "8px",
                      borderRadius: "8px",
                      border: '1px solid rgba(0, 0, 0, 0.23)',
                      color: '#696969',
                      height: '56px',
                      overflow: 'auto'
                    }}
                    autoFocus
                  />
                </Box>
              ) : (
                <Typography
                  width={"70%"}
                  variant="bodySm"
                  color={"#333"}
                  flexWrap={"wrap"}
                  sx={{
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: showFullDescription ? "unset" : 2,
                  }}
                >
                  {description || fileData?.file_description}
                </Typography>
              )}
            </Box>
            <Box
              sx={{ marginLeft: "24%", marginTop: "-14px", display: "flex" }}
            >
              {!isEditing && (
                <Button
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#333",
                    padding: "0px",
                    height: "auto",
                    minWidth: "auto",
                    textTransform: "math-auto",
                    borderBottom: "2px solid #FFA737",
                    borderRadius: "0px",
                    marginRight: "12px",
                  }}
                  onClick={handleShowMoreClick}
                >
                  Show {!showFullDescription ? "more" : "less"}
                </Button>
              )}
              {isEditing ? (
                <Box sx={{ marginTop: "8px" }}>
                  <Button
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#333",
                      padding: "0px 12px",
                      height: "34px",
                      minWidth: "auto",
                      textTransform: "capitalize",
                      marginRight: "12px",
                      borderRadius: "8px",
                      border: "1px solid #FFA737",
                    }}
                    onClick={handleSaveClick}
                  >
                    Save
                  </Button>
                  <Button
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#333",
                      padding: "0px",
                      height: "auto",
                      minWidth: "auto",
                      textTransform: "capitalize",
                    }}
                    onClick={handleCancelEditClick}
                  >
                    Cancel
                  </Button>
                </Box>
              ) : (
                <Box
                  onClick={handleEditClick}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Icons
                    name="edit"
                    fill="#333333"
                    width={16}
                    height={16}
                    style={{ marginRight: "4px", cursor: "pointer" }}
                  />
                  <Button
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#333",
                      padding: "0px",
                      height: "auto",
                      minWidth: "auto",
                      textTransform: "capitalize",
                      borderBottom: "2px solid #FFA737",
                      borderRadius: "0px",
                    }}
                  >
                    Edit
                  </Button>
                </Box>
              )}
            </Box>
            {isVideo ? (
              <>
                <VideoPreview
                  file={fileData?.file_url}
                  fileName={fileData?.file_name}
                />
                {(
                  <Box className="recTimeStamp">
                    <Accordion
                      sx={{
                        ".MuiButtonBase-root": {
                          minHeight: "30px",
                          height: "30px",
                          backgroundColor: "transparent",
                          padding: "0px",
                          fontSize: "14px",
                          fontWeight: 600,
                        },
                        ".MuiButtonBase-root .Mui-expanded": {
                          minHeight: "30px",
                          height: "30px",
                        },
                        ".MuiAccordionDetails-root": {
                          padding: "0px",
                        },
                        ".MuiAccordionSummary-content .Mui-expanded": {
                          margin: "0px",
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <Icons
                            name="chevronDown"
                            fill="#8E8D8A"
                            width={24}
                            height={24}
                          />
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        Recording timestamp
                      </AccordionSummary>
                      <AccordionDetails>
                        <TextField
                          name="subDesc"
                          value={recStamp}
                          onChange={(e) => setRecStamp(e.target.value)}
                          type="text"
                          placeholder="E.g. 00:00 Introduction..."
                          multiline
                          minRows={6}
                          maxRows={6}
                          variant="outlined"
                          sx={{
                            color: "#333",
                            marginBottom: "16px",
                            width: "100%",
                            ".MuiInputBase-input": {
                              paddinTop: "5px",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                              borderRadius: "8px",
                              border: "1px solid #D2D2D0",
                            },
                            ".css-3j0wgu-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                              { border: "1px solid #C61341" },
                            "& .css-5ifmys-MuiFormHelperText-root.Mui-error": {
                              color: "#181A20",
                            },
                            "& .css-5ifmys-MuiFormHelperText-root": {
                              marginLeft: 0,
                            },
                            "&:hover": { background: "#FFF8EC" },
                            "& .MuiInputBase-input": {
                              color: "#333",
                              fontSize: "14px",
                              lineHeight: "18.2px",
                            },
                          }}
                        />
                        <Button
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#333",
                            padding: "0px 12px",
                            height: "38px",
                            minWidth: "50px",
                            textTransform: "capitalize",
                            marginRight: "12px",
                            borderRadius: "8px",
                            border: "1px solid #FFA737",
                          }}
                          onClick={handleRecordTimestamp}
                        >
                          Save
                        </Button>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                )}
                {fileData?.version_history !== null && (
                  <Box className="recTimeStamp">
                    <Accordion
                      sx={{
                        ".MuiButtonBase-root": {
                          minHeight: "30px",
                          height: "30px",
                          backgroundColor: "transparent",
                          padding: "0px",
                          fontSize: "14px",
                          fontWeight: 600,
                        },
                        ".MuiButtonBase-root .Mui-expanded": {
                          minHeight: "30px",
                          height: "30px",
                        },
                        ".MuiAccordionDetails-root": {
                          padding: "0px",
                        },
                        ".MuiAccordionSummary-content .Mui-expanded": {
                          margin: "0px",
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <Icons
                            name="chevronDown"
                            fill="#8E8D8A"
                            width={24}
                            height={24}
                          />
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        Version history
                      </AccordionSummary>
                      <AccordionDetails>
                        {fileData?.version_history &&
                          fileData?.version_history?.map((history) => (
                            <Typography key={history.id}>
                              {history.file_name}
                            </Typography>
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                )}
              </>
            ) : (
              <>
                <FilesPreview file={fileData?.file_url} />
                {fileData?.version_history !== null && (
                  <Box className="recTimeStamp">
                    <Accordion
                      sx={{
                        ".MuiButtonBase-root": {
                          minHeight: "30px",
                          height: "30px",
                          backgroundColor: "transparent",
                          padding: "0px",
                          fontSize: "14px",
                          fontWeight: 600,
                        },
                        ".MuiButtonBase-root .Mui-expanded": {
                          minHeight: "30px",
                          height: "30px",
                        },
                        ".MuiAccordionDetails-root": {
                          padding: "0px",
                        },
                        ".MuiAccordionSummary-content .Mui-expanded": {
                          margin: "0px",
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <Icons
                            name="chevronDown"
                            fill="#8E8D8A"
                            width={24}
                            height={24}
                          />
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        Version history
                      </AccordionSummary>
                      <AccordionDetails>
                        {fileData?.version_history &&
                          fileData?.version_history.map((history) => {
                         
                            const updatedAt = new Date(history.updated_at);

                            // Get month name
                            const monthNames = [
                              "Jan",
                              "Feb",
                              "Mar",
                              "Apr",
                              "May",
                              "Jun",
                              "Jul",
                              "Aug",
                              "Sep",
                              "Oct",
                              "Nov",
                              "Dec",
                            ];
                            const monthIndex = updatedAt.getMonth();
                            const month = monthNames[monthIndex];

                            // Format the date
                            const formattedDate = `${month} ${updatedAt.getDate()}, ${updatedAt.getFullYear()} ${updatedAt.toLocaleTimeString(
                              [],
                              { hour: "2-digit", minute: "2-digit" }
                            )}`;

                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                key={history.id}
                              >
                                <Typography
                                  sx={{
                                    marginRight: "12px",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  {formattedDate}
                                </Typography>
                                <Avatar
                                  sx={{
                                    width: 20,
                                    height: 20,
                                    fontSize: "12px",
                                    bgcolor: "#F4F6FF",
                                    color: "#000000",
                                  }}
                                >
                                  {history.updated_by.charAt(0).toUpperCase()}
                                </Avatar>
                                <Typography
                                  variant="bodyLg"
                                  color={"#696969"}
                                  sx={{
                                    textTransform: "capitalize",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  {history.updated_by}
                                </Typography>
                              </Box>
                            );
                          })}
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      </RightDrawer>
      {bugDeta && (
        <RightDrawer
          open={bugDeta}
          handleClose={() => {
            setBugDeta(false);
          }}
          title="Bug Reports"
          btnText="View File"
          handleButton={() => handleViewFile()}
          extra={"bug"}
        >
          <BugDetail
            bugDetData={bugDetail}
            bugStatus={fileData?.bug_list[0].record_status.toLowerCase()}
            goBack={true} bugDeviceDetail={{
              browser: "",
              browserVersion: "",
              device:"",
              os: "",
            }}          />
        </RightDrawer>
      )}
    </>
  );
};

export default FileDetail;
