export { ReactComponent as Home } from "../assets/icons/filled/home.svg";
export { ReactComponent as FolderOpen } from "../assets/icons/filled/folder-open.svg";
export { ReactComponent as BookOpen } from "../assets/icons/filled/book-open.svg";
export { ReactComponent as Student } from "../assets/icons/filled/Student.svg";
export { ReactComponent as Add } from "../assets/icons/filled/add.svg";
export { ReactComponent as Notification } from "../assets/icons/filled/notification.svg";
export { ReactComponent as UserArrowDown } from "../assets/icons/system/arrow-down.svg";
export { ReactComponent as Settings } from "../assets/icons/system/settings.svg";
export { ReactComponent as BugIcon } from "../assets/icons/system/Bug.svg";
export { ReactComponent as Logout } from "../assets/icons/system/Logout.svg";
export { ReactComponent as Filter } from "../assets/icons/system/Filter.svg";
export { ReactComponent as TextFile } from "../assets/icons/filled/Text-file.svg";
export { ReactComponent as Description } from "../assets/icons/system/Description.svg";
export { ReactComponent as Bug } from "../assets/icons/system/Bug.svg";
export { ReactComponent as Warning } from "../assets/icons/system/warning.svg";
export { ReactComponent as Minus } from "../assets/icons/system/Minus.svg";
export { ReactComponent as Search } from "../assets/icons/system/Search.svg";
export { ReactComponent as Close } from "../assets/icons/system/Close.svg";
export { ReactComponent as ChevronLeft } from "../assets/icons/system/chevron-left.svg";
export { ReactComponent as Undo } from "../assets/icons/system/undo.svg";
export { ReactComponent as UndoDark } from "../assets/icons/system/UndoDark.svg";
export { ReactComponent as ChevronRight } from "../assets/icons/system/chevron-right.svg";
export { ReactComponent as Recording } from "../assets/icons/filled/Recording.svg";
export { ReactComponent as Show } from "../assets/icons/system/Show.svg";
export { ReactComponent as Hide } from "../assets/icons/system/Hide.svg";
export { ReactComponent as WarningRed } from "../assets/icons/system/WarningRed.svg";
export { ReactComponent as Edit } from "../assets/icons/system/edit.svg";
export { ReactComponent as CheckCircle } from "../assets/icons/system/check_circle.svg";
export { ReactComponent as Cancel } from "../assets/icons/system/cancel.svg";
export { ReactComponent as GalleryIcon } from "../assets/icons/system/GalleryIcon.svg";
export { ReactComponent as RecordingShow } from "../assets/icons/system/RecordingShow.svg";
export { ReactComponent as Trash } from "../assets/icons/filled/trash.svg";
export { ReactComponent as OutLineBook } from "../assets/icons/system/OutLineBook.svg";
export { ReactComponent as Info } from "../assets/icons/system/info.svg";
export { ReactComponent as CheckTick } from "../assets/icons/system/check_24px.svg";
export { ReactComponent as Export } from "../assets/icons/system/Export.svg";
export { ReactComponent as Mail } from "../assets/icons/filled/mail.svg";
export { ReactComponent as Phone } from "../assets/icons/filled/phone.svg";
export { ReactComponent as Upload } from "../assets/icons/filled/upload.svg";
export { ReactComponent as Replace } from "../assets/icons/system/Replace.svg";
export { ReactComponent as ChevronDown } from "../assets/icons/system/chevron-down.svg";
export { ReactComponent as ChevronUp } from "../assets/icons/system/chevron-up.svg";
export { ReactComponent as Expand } from "../assets/icons/system/expand-1.svg";
export { ReactComponent as ExpandVideoFull } from "../assets/icons/video/expandFullScreen.svg";
export { ReactComponent as Forward15 } from "../assets/icons/video/forward_15.svg";
export { ReactComponent as PauseSmallIcon } from "../assets/icons/video/pauseVideo.svg";
export { ReactComponent as PlayIconCircle } from "../assets/icons/video/playIconCircle.svg";
export { ReactComponent as PlaySmallIcon } from "../assets/icons/video/play_arrow.svg";
export { ReactComponent as RePlay15 } from "../assets/icons/video/replay-15.svg";
export { ReactComponent as SkipNext } from "../assets/icons/video/skip_next.svg";
export { ReactComponent as SkipPrevious } from "../assets/icons/video/skip_previous.svg";
export { ReactComponent as PdfIcon } from "../assets/icons/filled/pdfIcon.svg";
export { ReactComponent as DescIcon } from "../assets/icons/filled/descIcon.svg";
export { ReactComponent as LockReset } from "../assets/icons/system/lock-reset.svg";
export { ReactComponent as users } from "../assets/icons/system/users.svg";

