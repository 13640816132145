import React, { useRef,Dispatch, SetStateAction } from "react";
import Typography from "@mui/material/Typography";
import IconComponent from "../../components/Icons";
import { Box, MenuItem, Select, TextField } from "@mui/material";
import Switch from "@mui/material/Switch";
import GlobalPopupModal from "../Popup/dialog";
import SubjectMountImg from "../../assets/images/SubjectMount.png";
import { HexColorPicker } from "react-colorful";
import "../../index.css";
import {
  createAos,
  createSubject,
  createSubjectGroup,
  createTopic,
  updateEditAOS,
  updateEditSubject,
  updateEditTopic,
  updateEditSubjectGroup
} from "../../services/api/subjects";
import { useAppSelector } from "../../store/hooks";
import FileInput from "../DragNDrop/FileInput";
import { useEffect } from "react";


interface EditSub {
  id?: number;
  mst_subject_group_id?: number;
  subject?: string;
  subject_description?: string | null;
  subject_code?: string | null;
  aos_id?: string | null;
  area_of_study?: string | null;
  topicid?: string | null;
  subid?: string | null;
  area_of_study_description?: string | null;
  area_of_study_code?: string | null;
  area_of_study_icon?: string | null;
  aos_code?: string | null;
  topic_description?: string | null;
  topic_code?: string | null;
  topic?: string | null;
  subject_icon?: string | null;
  subject_colour?: string;
  updated_by?: string | null;
  record_status?: string;
  createdAt?: string;
  updatedAt?: string;
  sub_code?: string;
  subject_group?: string;
  description?: string;
  aoslist?: string[];
  types?: [
    {
      status: unknown;
      file_type: string;
    },
  ];
  ucat_flag?: boolean;
}

type GetSub = {
  subject_group_id?: number;
  subject_group?: number;
  subject_id?: number;
  area_of_id?: number;
  topic_id?: number;
  type_id?: number;
  file_type_id?: number;
  search_key?: string;
};

type ToastState = {
  type: string;
  message: string;
};
export interface SubjectDetailsModalProps {
  titleName: string;
  name: string;
  description: string;
  subjectCode?: string;
  AosCodee?: string;
  iconName: string;
  open: boolean;
  handleCloseModal: () => void;
  type?: string;
  editSubData?: EditSub;
  setToast?: Dispatch<SetStateAction<ToastState | null>>;
  setSeleTabData?: React.Dispatch<React.SetStateAction<GetSub>>;
  seleTabData?: GetSub;
  isCourseDetailsModalOpen?: boolean;
  isAosModalOpen?: boolean;
}

const subjectColors = [
  "#007AFF",
  "#EF6380",
  "#C23916",
  "#2BB568",
  "#54CAFB",
  "#BF6BDD",
];

const SubjectDetailsModal: React.FC<SubjectDetailsModalProps> = (props) => {
  const {
    titleName,
    seleTabData,
    name,
    description,
    subjectCode,
    AosCodee,
    iconName,
    open,
    handleCloseModal,
    type,
    editSubData,
    setToast,
    setSeleTabData,
    isCourseDetailsModalOpen,
    isAosModalOpen,
  } = props;
  const [selectedColor, setSelectedColor] = React.useState<string | null>(null);
  const [selectCustomClr, setSelectCustomClr] = React.useState(true);
  const [selectedTypes, setSelectedTypes] = React.useState<string[]>([]);
  const [color, setColor] = React.useState("");
  const initialFormData = {
    subCode: "",
    subName: "",
    subDesc: "",
    icon: "",
    colorCode: "",
    types: [
      {
        file_type: "",
      },
    ],
    ucat_flag: false
  };
  const errorRef = useRef<HTMLSpanElement>(null);

  console.log(setToast, "setToast");
  const [data, setData] = React.useState<{
    subCode: string;
    subName: string;
    area_of_study_id?: string;
    subDesc: string;
    icon: string | File;
    colorCode: string | null;
    types: {
      file_type: string | null;
      ext_type?: any;
    }[];
    ucat_flag: boolean;
  }>(initialFormData);
  const auth = useAppSelector((state) => state.auth);
  // const { subDesc, subName, subCode } = data;
  const { subDesc, subName,subCode,area_of_study_id } = data;

  useEffect(() => {
    if (editSubData) {
      if (type === "edit aos") {
        setData({
          subName: editSubData.area_of_study || "",
          subDesc: editSubData.area_of_study_description || "",
          subCode: editSubData.aos_code || "",
          icon: editSubData.area_of_study_icon || "",
          colorCode: null,
          types: [{ file_type: null }],
          ucat_flag: false
        });
      } else if (type === "edit topic") {
        setData({
          subName: editSubData.topic || "",
          subDesc: editSubData.topic_description || "",
          subCode: editSubData.topic_code || "",
          icon: "",
          colorCode: null,
          types: [{ file_type: null }],
          ucat_flag: false
        });
      }
      else if (type === "edit sub-group") {
        setData({
          subName: editSubData.subject_group || "",
          subDesc: editSubData.description || "",
          subCode: editSubData.topic_code || "",
          icon: "",
          colorCode: null,
          types: [{ file_type: null }],
          ucat_flag: false
        });
      }
      else {
        setData({
          subName: `${editSubData.subject || ""
            }`,
          subDesc: editSubData.subject_description || "",
          subCode: editSubData.sub_code || "",
          icon: editSubData.subject_icon || "",
          colorCode: editSubData.subject_colour || null,
          types: editSubData.types ? editSubData.types : [{ file_type: "" }],
          ucat_flag: editSubData.ucat_flag || false,
        });
        setSelectedColor(editSubData.subject_colour || null);
      }
    }
  }, [editSubData]);

  useEffect(() => {
    if (editSubData && editSubData.types) {
      const activeTypes = editSubData.types
        .filter(type => type.status)
        .map(type => type.file_type);
      setSelectedTypes(activeTypes);
    }
  }, [editSubData]);

  const handleChange = (fileType: string) => {
    if (selectedTypes.includes(fileType)) {
      setSelectedTypes(selectedTypes.filter((type) => type !== fileType));
    } else {
      setSelectedTypes([...selectedTypes, fileType]);
    }
    const isAcknowledged = selectedTypes.includes(fileType);
    const studentAcknowledgementStatus = isAcknowledged ? "false" : "true";
    console.log("student_acknowledgement_status:", studentAcknowledgementStatus);
  };

  const onChangeInput = (e: { target: { name: string; value: unknown } }) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  const handleHexCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newHexCode = event.target.value;
    setColor(newHexCode);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData((prevData) => ({
      ...prevData,
      ucat_flag: event.target.checked, // Updates the flag based on switch state
    }));
  };

  const handleColorClick = (color: string) => {
    setSelectedColor(color);
  };

  const HandleBack = () => {
    setSelectCustomClr(true);
  };
  const HandleChooseClr = () => {
    setSelectCustomClr(false);
  };

  console.log("editSubDataeditSubData", seleTabData);
  console.log("dataIcon", data.icon);

  const handleConfirmBtn = async (text: string) => {
    console.log("Data before accessing properties:", data);
    console.log(text);
    const formData = new FormData();
    if (type == "group") {
      formData.append("subject_group", data.subName);
      formData.append("description", data.subDesc);
      formData.append("subject_code", data.subCode)
      formData.append("sub_group_icon", data.icon);
      formData.append("sub_grp_colour", data.colorCode ? data.colorCode : "");
    } else if (type == "edit") {
      formData.append("subject", data.subName);
      if (typeof data.icon === "string") {
        formData.append("subject_icon", "");
      } else {
        formData.append("subject_icon", data.icon);
      }
      formData.append("subject_description", data.subDesc);
      formData.append("subject_code", data.subCode)
      formData.append("subject_colour", data.colorCode ? data.colorCode : "");
      formData.append("typeNames", selectedTypes.join(","));
      formData.append("ucat_flag",data.ucat_flag ? "true" : "false")
    } else if (type == "aos") {
      formData.append(
        "subject_group_id",
        seleTabData?.subject_group?.toString() || ""
      );
      formData.append("area_of_study", data.subName);
      formData.append("area_of_study_icon", data.icon);
      formData.append("area_of_study_description", data.subDesc);
      formData.append("aos_code", data.subCode)
      formData.append("subject_id", seleTabData?.subject_id?.toString() || "");
    } else if (type === "edit aos") {
      formData.append("area_of_study", data.subName);
      formData.append("area_of_study_icon", data.icon);
      formData.append("area_of_study_description", data.subDesc);
      formData.append("aos_code", data.subCode)
    } else if (type === "edit topic") {
      formData.append("topic", data.subName);
      formData.append("topic_description", data.subDesc);
      formData.append("topic_code", data.subCode)
      if(data.area_of_study_id){
        formData.append("area_of_study_id", data.area_of_study_id);
      }
      // formData.append("subject_id", seleTabData?.subject_id?.toString() || "");
      // formData.append(
      //   "area_of_study_id",
      //   seleTabData?.area_of_id?.toString() || ""
      // );
      formData.append("updated_by", "1");
      formData.append("record_status", "true");
    } else if (type === "edit sub-group") {
      formData.append("subject_group", data.subName);
      formData.append("description", data.subDesc);
    }
    else if (type == "topic") {
      formData.append(
        "subject_group_id",
        seleTabData?.subject_group?.toString() || ""
      );
      formData.append("topic", data.subName);
      formData.append("topic_description", data.subDesc);
      formData.append("topic_code", data.subCode)
      formData.append("subject_id", seleTabData?.subject_id?.toString() || "");
      formData.append(
        "area_of_study_id",
        seleTabData?.area_of_id?.toString() || ""
      );
      formData.append("updated_by", "1");
      formData.append("record_status", "true");
    } else {
      formData.append(
        "mst_subject_group_id",
        seleTabData?.subject_group?.toString() || ""
      );
      formData.append("subject", data.subName);
      formData.append("subject_icon", data.icon);
      formData.append("subject_description", data.subDesc);
      formData.append("subject_code", data.subCode)
      formData.append("subject_colour", data.colorCode ? data.colorCode : "");
      formData.append("ucat_flag",data.ucat_flag ? "true" : "false")
    }

    if (text == "Save") {
      if (type == "group") {
        await createSubjectGroup(formData, auth?.token)
          .then((response) => {
            console.log('response-save-->',response);
            handleCloseModal();
            setData(initialFormData);
            setSeleTabData &&
              setSeleTabData(() => {
                return {};
              });
          })
          .catch((err) => {
            console.log('save-->',err.response.data);
          });
      } else if (type === "edit") {
        await updateEditSubject(formData, auth?.token, editSubData?.id || null)
          .then((response) => {
            console.log(response);
            handleCloseModal();
            setData(initialFormData);
            setSeleTabData &&
              setSeleTabData((prevFilters) => {
                return {
                  subject_group: prevFilters.subject_group,
                };
              });
          })
          .catch((err) => {
            console.log('edit-->',err.response.data);
          });
      } else if (type === "edit aos") {
        const aosId: number | null = editSubData?.id || null;
        await updateEditAOS(formData, auth?.token, aosId)
          .then((response) => {
            console.log(response);
            handleCloseModal();
            setData(initialFormData);
            setSeleTabData &&
              setSeleTabData((prevFilters) => {
                return {
                  subject_group: prevFilters.subject_group,
                  subject_id: prevFilters.subject_id,
                };
              });
          })
          .catch((err) => {
            console.log('edit aos-->',err.response.data);
          });
      } else if (type === "edit topic") {
        await updateEditTopic(formData, auth?.token, editSubData?.id || null)
          .then((response) => {
            console.log('response-edit-topic-->',response);
            handleCloseModal();
            setData(initialFormData);
            setSeleTabData &&
              setSeleTabData((prevFilters) => {
                return {
                  subject_group: prevFilters.subject_group,
                  subject_id: prevFilters.subject_id,
                  area_of_id: prevFilters.area_of_id,
                };
              });
          })
          .catch((err) => {
            if(err.response.data.success === false){
              console.log('edit topic-->',err.response.data);
              if (errorRef.current) {
                errorRef.current.style.display = "block"; // Show error
              }

            }
            console.log('edit topic-->',err.response.data);
          });
      }
      else if (type === "edit sub-group") {
        await updateEditSubjectGroup(formData, auth?.token, editSubData?.id || null)
          .then((response) => {
            console.log(response, 'showww');
            handleCloseModal();
            setData(initialFormData);
            setSeleTabData &&
              setSeleTabData(() => {
                return {};
              });
          })
          .catch((err) => {
            console.log(err.response.data, 'showwwshowww');
          });//here
      }
      else if (type === "aos") {
        await createAos(formData, auth?.token)
          .then((response) => {
            console.log(response);
            handleCloseModal();
            setData(initialFormData);
            setSeleTabData &&
              setSeleTabData((prevFilters) => {
                return {
                  subject_group: prevFilters.subject_group,
                  subject_id: prevFilters.subject_id,
                };
              });
          })
          .catch((err) => {
            console.log('aos-->',err.response.data);
          });
      } else if (type === "topic") {
        await createTopic(formData, auth?.token)
          .then((response) => {
            console.log(response);
            handleCloseModal();
            setData(initialFormData);
            setSeleTabData &&
              setSeleTabData((prevFilters) => {
                return {
                  subject_group: prevFilters.subject_group,
                  subject_id: prevFilters.subject_id,
                  area_of_id: prevFilters.area_of_id,
                };
              });
          })
          .catch((err) => {
            console.log('topic-->',err.response.data);
          });
      } else {
        await createSubject(formData, auth?.token)
          .then((response) => {
            console.log(response);
            handleCloseModal();
            setData(initialFormData);
            setSeleTabData &&
              setSeleTabData((prevFilters) => {
                return {
                  subject_group: prevFilters.subject_group,
                };
              });
          })
          .catch((err) => {
            console.log('topic else-->',err.response.data);
          });
      }
    } else {
      setSelectedColor(color);
      setSelectCustomClr(true);
    }
  };


  const handleSvgContentChange = (svgContent: File | string) => {
    console.log("Received SVG Content:", svgContent);
    setData({ ...data, ["icon"]: svgContent });
  };

  useEffect(() => {
    setData({ ...data, ["colorCode"]: selectedColor });
  }, [selectedColor]);

  return (
    <div>
      <GlobalPopupModal
          titleName={titleName}
          open={open}
          handleCloseModal={() => {
            handleCloseModal();
            setData(initialFormData);
          }}
          handleFooterBtn={() => {
            handleConfirmBtn(selectCustomClr ? "Save" : "Select colour");
            
          }}
          btnText={selectCustomClr ? "Save" : "Select colour"}
          modalWidth={"396px"}
        >
        
        
        
        
        {

        }
        {selectCustomClr ? (
          <Box>
            {type === "topic" || type === "edit topic" && (
              <>
                <Typography
                  color={"#333333"}
                  variant="detailBold"
                  sx={{ display: "block", marginBottom: "4px" }}
                >
                  {"Select AOS"}
                </Typography>
                
                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  name="area_of_study_id"
                  displayEmpty
                  sx={{
                    color: "#333",
                    marginBottom: "16px",
                    width: "100%",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderRadius: "8px",
                      border: "1px solid #D2D2D0",
                    },
                    ".css-3j0wgu-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                      { border: "1px solid #C61341" },
                    "& .css-5ifmys-MuiFormHelperText-root.Mui-error": {
                      color: "#181A20",
                    },
                    "& .css-5ifmys-MuiFormHelperText-root": { marginLeft: 0 },
                    "&:hover": { background: "#FFF8EC" },
                    ".css-19z3763-MuiInputBase-input-MuiOutlinedInput-input": {
                      color: "#333",
                      fontSize: "14px",
                      lineHeight: "18.2px",
                    },
                    "& fieldset": {top: "0"},
                    "& fieldset legend": {
                      display: "none",
                    }
                  }}
                  label="Select AOS"
                  value={area_of_study_id||""}
                  onChange={onChangeInput}
                  // labelId="demo-controlled-open-select-label"
                >
                 <MenuItem
                 sx={{ display: 'none' }}
                  value={""}
                  disabled
                  >
                 Select AOS
                 </MenuItem>
                 {editSubData?.aoslist && editSubData.aoslist.map((val:any,index:number)=>{
                    return (
                    <MenuItem
                      sx={{
                        gap: "8px",
                        padding: "12px",
                        alignItems: "center",
                        flexDirection: "row",
                        borderBottom: "1px solid #D2D2D0",
                      }}
                      key={index}
                      value={val.area_of_study_id}
                    >
                      {val.aos_code}
                    </MenuItem>
                    )
                 })}
                 
                </Select>
              </>
            )}


          {/* {(type === "topic" || type === "edit topic") && (
            <>
              <Typography
                color={"#333333"}
                variant="detailBold"
                sx={{ display: "block", marginBottom: "4px" }}
              >
                {"Select AOS"}
              </Typography>

              <Select
                inputProps={{ "aria-label": "Without label" }}
                name="area_of_study_id"
                displayEmpty
                sx={{
                  color: "#333",
                  marginBottom: "16px",
                  width: "100%",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                    border: "1px solid #D2D2D0",
                  },
                  ".css-3j0wgu-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                    { border: "1px solid #C61341" },
                  "& .css-5ifmys-MuiFormHelperText-root.Mui-error": {
                    color: "#181A20",
                  },
                  "& .css-5ifmys-MuiFormHelperText-root": { marginLeft: 0 },
                  "&:hover": { background: "#FFF8EC" },
                  ".css-19z3763-MuiInputBase-input-MuiOutlinedInput-input": {
                    color: "#333",
                    fontSize: "14px",
                    lineHeight: "18.2px",
                  },
                  "& fieldset": { top: "0" },
                  "& fieldset legend": {
                    display: "none",
                  }
                }}
                label="Select AOS"
                value={area_of_study_id || ""}
                onChange={onChangeInput}
              >
                <MenuItem value={""} disabled sx={{display: "none"}}>
                  Select AOS
                </MenuItem>

                
                {editSubData?.aoslist &&
                  editSubData.aoslist
                    .filter((val: any) => val.area_of_study !== "AOS UCAT") // Your filter logic
                    .map((val: any, index: number) => (
                      <MenuItem
                        sx={{
                          gap: "8px",
                          padding: "12px",
                          alignItems: "center",
                          flexDirection: "row",
                          borderBottom: "1px solid #D2D2D0",
                        }}
                        key={index}
                        value={val.area_of_study_id}
                      >
                        {val.aos_code}
                      </MenuItem>
                    ))}

                
                {editSubData?.aoslist?.filter((val: any) => val.area_of_study !== "AOS UCAT").length === 0 && (
                  <MenuItem disabled>No results</MenuItem>
                )}
              </Select>
            </>
        )} */}





            {
              isAosModalOpen &&
              <Box>
                <Typography
                  color={"#333333"}
                  variant="detailBold"
                  sx={{ display: "block", marginBottom: "4px" }}
                >
                  {AosCodee}
                </Typography>
                
                <TextField
                  name="subCode"
                  value={subCode}
                  onChange={onChangeInput}
                  id="text"
                  variant="outlined"
                  sx={{
                    color: "#333",
                    marginBottom: "16px",
                    width: "100%",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderRadius: "8px",
                      border: "1px solid #D2D2D0",
                    },
                    ".css-3j0wgu-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                      { border: "1px solid #C61341" },
                    "& .css-5ifmys-MuiFormHelperText-root.Mui-error": {
                      color: "#181A20",
                    },
                    "& .css-5ifmys-MuiFormHelperText-root": { marginLeft: 0 },
                    "&:hover": { background: "#FFF8EC" },
                    ".css-19z3763-MuiInputBase-input-MuiOutlinedInput-input": {
                      color: "#333",
                      fontSize: "14px",
                      lineHeight: "18.2px",
                    },
                  }}
                />
              </Box>
            }
          

            {/* new toggle button added */}

        {(type === "subject" || type === "edit") && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              paddingBottom: "5px",
            }}
          >
            <p style={{margin: "0"}}>Uncategorized</p>
            <Switch
              inputProps={{ "aria-label": "controlled" }}
              checked={data.ucat_flag} // Ensure switch reflects state
              onChange={handleSwitchChange} // Handle change
              sx={{
                "& .MuiSwitch-switchBase": {
                  color: "#D2D2D0",
                  "&.Mui-checked": {
                    color: "#FFA737",
                    "& + .MuiSwitch-track": {
                      backgroundColor: "#FFA737",
                      opacity: 0.6,
                      borderRadius: "7px",
                      border: "1.5px solid #FFA737",
                    },
                  },
                },
                "& .MuiSwitch-track": {
                  backgroundColor: "transparent",
                  opacity: 1,
                  borderRadius: "7px",
                  border: "1.5px solid #D2D2D0",
                },
              }}
            />
          </Box>
        )}

        {/* new toggle button added */}
           
            
            <Typography
              color={"#333333"}
              variant="detailBold"
              sx={{ display: "block", marginBottom: "4px" }}
            >
              {name}
            </Typography>

            <TextField
              name="subName"
              value={subName}
              onChange={onChangeInput}
              id="text"
              variant="outlined"
              sx={{
                color: "#333",
                marginBottom: "16px",
                width: "100%",
                ".MuiOutlinedInput-notchedOutline": {
                  borderRadius: "8px",
                  border: "1px solid #D2D2D0",
                },
                ".css-3j0wgu-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                  { border: "1px solid #C61341" },
                "& .css-5ifmys-MuiFormHelperText-root.Mui-error": {
                  color: "#181A20",
                },
                "& .css-5ifmys-MuiFormHelperText-root": { marginLeft: 0 },
                "&:hover": { background: "#FFF8EC" },
                ".css-19z3763-MuiInputBase-input-MuiOutlinedInput-input": {
                  color: "#333",
                  fontSize: "14px",
                  lineHeight: "18.2px",
                },
              }}
            />
            {
              isCourseDetailsModalOpen && <Box>
                <Typography
                  color={"#333333"}
                  variant="detailBold"
                  sx={{ display: "block", marginBottom: "4px" }}
                >
                  {subjectCode}
                </Typography>
                <TextField
                  name="subCode"
                  value={subCode}
                  onChange={onChangeInput}
                  id="text"
                  variant="outlined"
                  sx={{
                    color: "#333",
                    marginBottom: "16px",
                    width: "100%",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderRadius: "8px",
                      border: "1px solid #D2D2D0",
                    },
                    ".css-3j0wgu-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                      { border: "1px solid #C61341" },
                    "& .css-5ifmys-MuiFormHelperText-root.Mui-error": {
                      color: "#181A20",
                    },
                    "& .css-5ifmys-MuiFormHelperText-root": { marginLeft: 0 },
                    "&:hover": { background: "#FFF8EC" },
                    ".css-19z3763-MuiInputBase-input-MuiOutlinedInput-input": {
                      color: "#333",
                      fontSize: "14px",
                      lineHeight: "18.2px",
                    },
                  }}
                />
              </Box>
            }

            {type === "edit topic" && (
              <span 
                id="edit-topic-error"
                ref={errorRef}
                style={{
                  display: "none",
                  color: "red",
                  fontSize: "12px",
                  marginTop: "-12px",
                  marginLeft: "4px",
                }}
              >
                AOS and topic AOS do not match/Does not exist
              </span>
             )}

            <Typography
              color={"#333333"}
              variant="detailBold"
              sx={{ display: "block", marginBottom: "4px" }}
            >
              {description}
            </Typography>
            <TextField
              name="subDesc"
              value={subDesc}
              onChange={onChangeInput}
              type="text"
              multiline
              minRows={6}
              maxRows={6}
              variant="outlined"
              sx={{
                color: "#333",
                marginBottom: "16px",
                width: "100%",
                ".MuiInputBase-input": {
                  paddingTop: "7px",
                },
                ".MuiInputBase-root": {
                  padding: "12px 14px",
                },
                ".MuiOutlinedInput-notchedOutline": {
                  borderRadius: "8px",
                  border: "1px solid #D2D2D0",
                },
                ".css-3j0wgu-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                  { border: "1px solid #C61341" },
                "& .css-5ifmys-MuiFormHelperText-root.Mui-error": {
                  color: "#181A20",
                },
                "& .css-5ifmys-MuiFormHelperText-root": { marginLeft: 0 },
                "&:hover": { background: "#FFF8EC" },
                "& .MuiInputBase-input": {
                  color: "#333 !important",
                  fontSize: "14px !important",
                  lineHeight: "18.2px !important",
                },
              }}
            />
            {type !== "topic" && type !== "edit topic" && (
              <>
                <Typography
                  color={"#333333"}
                  variant="detailBold"
                  sx={{ display: "flex", marginBottom: "-4px" }}
                >
                  {iconName}
                </Typography>
                <Typography color={"#696969"} variant="detailBold" sx={{}}>
                  Accepted file type: SVG
                </Typography>
                <Box
                  sx={{
                    width: "150px",
                    height: "150px",
                    marginBottom: "16px",
                    borderRadius: "8px",
                    border: "1px solid #EFEFEF",
                  }}
                >
                  {/* Upload svg div  */}
                  <FileInput
                    onSvgContentChange={handleSvgContentChange}
                    iconData={data.icon}
                  />
                </Box>
                {type === "edit" && (
                  <>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        marginBottom: "-5px",
                      }}
                    >
                      Types
                    </Typography>
                    {data.types.filter(type => type.ext_type === "pdf").map((type, index) => (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: "10px",
                        }}
                        key={index}
                      >
                        <Typography
                          sx={{ fontSize: "14px", fontWeight: "400" }}
                        >
                          {type.file_type}
                        </Typography>
                        <Box>
                          <Switch
                            checked={
                              type.file_type !== null &&
                              selectedTypes.includes(type.file_type)
                            }
                            onChange={() => {
                              // Ensure we handle only non-null file types
                              if (type.file_type !== null) {
                                handleChange(type.file_type);
                              }
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                            sx={{
                              "& .MuiSwitch-switchBase": {
                                color: "#D2D2D0",
                                "&.Mui-checked": {
                                  color: "#FFA737",
                                  "& + .MuiSwitch-track": {
                                    backgroundColor: "#FFA737",
                                    opacity: 0.6,
                                    border: "1.5px solid #FFA737",
                                  },
                                },
                              },
                              "& .MuiSwitch-track": {
                                borderRadius: 26 / 2,
                                backgroundColor: "transparent",
                                opacity: 1,
                                border: "1.5px solid #D2D2D0",
                              },
                            }}
                          />
                        </Box>
                      </Box>
                    ))}
                  </>
                )}
                {type !== "edit aos" && type !== "aos" && (
                  <>
                    <Typography
                      color={"#333333"}
                      variant="detailBold"
                      sx={{ display: "flex", marginBottom: "-4px" }}
                    >
                      Subject colour
                    </Typography>
                    <Box sx={{ marginTop: "12px" }}>
                      <Box sx={{ display: "flex", gap: "9px" }}>
                        {subjectColors.map((color, index) => (
                          <div
                            key={index}
                            style={{
                              border:
                                selectedColor === color
                                  ? `1px solid ${color}`
                                  : "0px",
                              padding: selectedColor === color ? "3px" : "0px",
                              borderRadius: "50%",
                            }}
                          >
                            <div
                              key={index}
                              style={{
                                background: color,
                                width:
                                  selectedColor === color ? "34px" : "40px",
                                height:
                                  selectedColor === color ? "34px" : "40px",
                                borderRadius: "50%",
                              }}
                              onClick={() => handleColorClick(color)}
                            />
                          </div>
                        ))}
                        <div
                          style={{
                            border: "1px solid ",
                            borderColor:
                              selectedColor &&
                                !subjectColors.includes(selectedColor)
                                ? selectedColor
                                : "#FFA737",
                            padding:
                              selectedColor &&
                                !subjectColors.includes(selectedColor)
                                ? "3px"
                                : "0px",
                            borderRadius: "50%",
                            display: "flex",
                            cursor: "pointer",
                            overflow: "hidden",
                            backgroundColor: "#FFF",
                            width: "40px",
                            height: "40px",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                          onClick={HandleChooseClr}
                        >
                          <Box
                            sx={{
                              background:
                                selectedColor &&
                                  !subjectColors.includes(selectedColor)
                                  ? selectedColor
                                  : "#FFF",
                              width: "100%",
                              height: "100%",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            {selectedColor &&
                              !subjectColors.includes(selectedColor) ? (
                              <IconComponent
                                name="edit"
                                fill={"#FFF"}
                                width={20}
                                height={20}
                              />
                            ) : (
                              <IconComponent
                                name="add"
                                fill={"#FFA737"}
                                width={24}
                                height={24}
                              />
                            )}
                          </Box>
                        </div>
                      </Box>
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
        ) : (
          <Box>
            <Typography
              color={"#333"}
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginBottom: "16px",
                width: "fit-content",
              }}
              onClick={HandleBack}
            >
              <IconComponent
                name="chevronLeft"
                fill={"#FFA737"}
                width={16}
                height={16}
              />
              <span
                style={{
                  borderBottom: "2px solid #FFA737",
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#333",
                }}
              >
                Back
              </span>
            </Typography>
            <Typography
              color={"#333"}
              sx={{
                fontSize: "16px",
                fontWeight: "600",
                marginBottom: "36px",
              }}
            >
              Select a custom colour
            </Typography>

            <Box
              sx={{
                width: "100%",
                height: "113px",
                borderRadius: "8px",
                background: color || "#EFEFEF",
                position: "relative",
                marginBottom: "16px",
              }}
            >
              <Box
                sx={{
                  padding: "12px",
                  background: "#FFF",
                  width: "41px",
                  height: "42px",
                  borderRadius: "50%",
                  position: "relative",
                  zIndex: "10",
                  top: "24px",
                  marginLeft: "16px",
                }}
              >
                <IconComponent
                  name="outLineBook"
                  fill={"#000"}
                  width={16}
                  height={16}
                />
              </Box>
              <Box
                sx={{ position: "absolute", bottom: "0px", width: "100%" }}
                component="img"
                src={SubjectMountImg}
                alt="LoginSmily"
              />
            </Box>
            <Box
              sx={{
                background: "#F4F6FF",
                borderRadius: "8px",
                width: "100%",
                padding: "12px",
              }}
            >
              <Typography
                color={"#333333"}
                variant="detailBold"
                sx={{ display: "block", marginBottom: "4px" }}
              >
                Colour hex
              </Typography>
              <Box sx={{ display: "flex", gap: "12px" }}>
                <TextField
                  value={color}
                  onChange={handleHexCodeChange}
                  id="text"
                  variant="outlined"
                  sx={{
                    color: "#333",
                    marginBottom: "16px",
                    maxWidth: "136px",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderRadius: "8px",
                      border: "1px solid #D2D2D0",
                    },
                    ".css-3j0wgu-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                      { border: "1px solid #C61341" },
                    "& .css-5ifmys-MuiFormHelperText-root.Mui-error": {
                      color: "#181A20",
                    },
                    "& .css-5ifmys-MuiFormHelperText-root": { marginLeft: 0 },
                    "&:hover": { background: "#FFF8EC" },
                    ".css-19z3763-MuiInputBase-input-MuiOutlinedInput-input": {
                      color: "#333",
                      fontSize: "14px",
                      lineHeight: "18.2px",
                    },
                  }}
                />
                <Box
                  sx={{
                    width: "44px",
                    height: "44px",
                    background: color || "#EFEFEF",
                    borderRadius: "8px",
                  }}
                />
              </Box>
              <HexColorPicker
                className="color_pickerCustom"
                color={color}
                onChange={setColor}
              />
            </Box>
          </Box>
        )}
      </GlobalPopupModal>
    </div>
  );
};

export default SubjectDetailsModal;

