import {
  Avatar,
  Badge,
  BadgeProps,
  Box,
  Link,
  Popover,
  Typography,
  styled,
  Modal,
} from "@mui/material";
import React, { useState } from "react";
import logo from "../../assets/images/Logo.png";
import Button from "../Button";
import Icons from "../Icons";
import Notification from "../Notification";
import { useNavigate } from "react-router-dom";
import BugList from "../BugReport/BugList";
import Settings from "../Settings";
import Cookies from "js-cookie";
import FileUpload from "../FileUpload";
import { getProfile } from "../../services/api/profile";
import ToastMessage from "../ToastMessage";
import { viewBugDetail } from "../../services/api/dashboard";
import { useDispatch } from "react-redux";
import { setOpen } from "../../store/uploaderSlice";

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#FF527F",
    color: "#FFFFFF",
  },
}));

interface FileUploadProps {
  open: boolean;
  handleClose: () => void;
  handleFilePopupOpen: () => void;
  uploadingFile: boolean;
  setUploadingFile: (newState: boolean) => void;
}

export interface BugDevice {
  browser: string;
  browserVersion: string;
  device: string;
  os: string; 

}
interface BugDetailState {
  id: number;
  bug_title: string;
  bug_description: string;
  speed: string;
  browser_info: string;
  attachment: string;
  file_id: number;
  file_name: string;
  file_type_id: number;
  file_type_name: string;
  area_of_study_id: number;
  area_of_study_name: string;
  subject_id: number;
  subject_name: string;
  topic_id: number;
  topic_name: string;
  sub_grp_id: number;
  sub_grp_name: string;
  sub_grp_code: string;
  student_id: number;
  reported_by: string;
  record_status: string;
  createdAt: string;
  updatedAt: string;
}
interface NotificationData {
  is_read: boolean;
}
const Header = ({
  open,
  handleClose,
  handleFilePopupOpen,
  uploadingFile,
  setUploadingFile,
}: FileUploadProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;

  const [drawer, setDrawer] = React.useState(false);
  const [drawer1, setDrawer1] = React.useState(false);
  const [resetPass, setResetPass] = React.useState<boolean>(false);
  const [passUpdatePopup, setPassUpdatePopup] = React.useState<boolean>(false);
  const [bug, setBug] = React.useState("");


  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const open1 = Boolean(anchorEl);
  const id = open1 ? "simple-popover" : undefined;
  const [toast, setToast] = React.useState<{
    type: string;
    message: string;
  } | null>(null);
  const [notificationData, setNotificationData] = useState<NotificationData[]>(
    []
  );
  const receiveNotificationData = (data: any) => {
    setNotificationData(data);
  };

  console.log(notificationData, "aaaa");
  const [openPopup, setOpenPopup] = React.useState(false);
  const [profileData, setProfileData] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    is_verified: false,
    record_status: "",
    role_id: "",
  });


  const [bugDeviceDetail, setBugDeviceDetail] = useState<BugDevice>({
      browser: "",
      browserVersion: "",
      device: "",
      os: "",
    });

  const [bugDetail, setBugDetail] = useState<BugDetailState>({
    id: 0,
    bug_title: "",
    bug_description: "",
    speed: "",
    browser_info: "",
    attachment: "",
    file_id: 0,
    file_name: "",
    file_type_id: 0,
    file_type_name: "",
    area_of_study_id: 0,
    area_of_study_name: "",
    subject_id: 0,
    subject_name: "",
    topic_id: 0,
    topic_name: "",
    sub_grp_id: 0,
    sub_grp_name: "",
    sub_grp_code: "",
    student_id: 0,
    reported_by: "",
    record_status: "",
    createdAt: "",
    updatedAt: "",
  });

  const handlePopupOpen = () => {
    setAnchorEl(null);
    setOpenPopup(true);
  };

  const handlePopupClose = () => {
    setOpenPopup(false);
    setResetPass(false);
  };

  const handleLogut = () => {
    setAnchorEl(null);
    Cookies.remove("loginToken");
    Cookies.remove("authAccess");
    navigate("/");
  };

  const handleBugReport = () => {
    setAnchorEl(null);
    setDrawer1(true);
  };

  const fetchBugDetailData = async (id: number) => {
    try {
      const response = await viewBugDetail(verifyAccess.token, id);
      setBugDetail(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleBugClick = (id: number) => {
    fetchBugDetailData(id);
  };

  const menuData = [
    {
      label: "Settings",
      icon: "settings",
      onClick: handlePopupOpen,
    },
    {
      label: "Bug reports",
      icon: "bugIcon",
      onClick: handleBugReport,
    },
    {
      label: "Log out",
      icon: "logout",
      onClick: handleLogut,
    },
  ];

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getProfile(verifyAccess.token);
      setProfileData(response.data.data[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <Box
        sx={{
          bgcolor: "background.default",
          paddingX: "36px",
          paddingY: "16px",
        }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          gap={"24px"}
        >
          <Box
            sx={{ width: "220px" }}
            component="img"
            src={logo}
            alt="brand-logo"
          />
          <Button
            onClick={() => handleFilePopupOpen()}
            text="Upload files"
            icon={<Icons name="add" fill="#333" width={16} height={16} />}
            textStyle={{ color: "#333" }}
          />
          <Button
            onClick={() => dispatch(setOpen(true))}
            text="Upload Class Rec"
            icon={<Icons name="add" fill="#333" width={16} height={16} />}
            textStyle={{ color: "#333" }}
          />
        </Box>

        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          gap={"32px"}
          paddingRight={"10px"}
        >
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              setDrawer(true);
            }}
            underline="none"
          >
            <StyledBadge
              badgeContent={
                notificationData.filter(
                  (notification) => !notification?.is_read
                ).length
              }
              color="secondary"
            >
              <Icons name="notification" width={24} height={24} />
            </StyledBadge>
          </Link>
          <Notification
            drawer={drawer}
            setDrawer={setDrawer}
            passData={receiveNotificationData}
          />
          <Link
            component="button"
            variant="button"
            onClick={handleClick}
            underline="none"
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            aria-describedby={id}
          >
            <Avatar
              sx={{
                bgcolor: "#007AFF",
                textDecoration: "none",
                width: "44px",
                height: "44px",
                marginRight: "8px",
                color: "#FFFFFF",
              }}
            >
              {profileData.first_name.charAt(0)}
            </Avatar>
            <Icons name="userArrowDown" fill="white" />
          </Link>
          <Popover
            id={id}
            open={open1}
            anchorEl={anchorEl}
            onClose={handleClose1}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            sx={{ left: "-26px", top: "7px" }}
          >
            <Box
              sx={{ borderRadius: "16px", width: "230px" }}
              display={"flex"}
              flexDirection={"column"}
            >
              <Box
                paddingX={"12px"}
                paddingY={"8px"}
                display={"flex"}
                flexDirection={"column"}
                borderBottom={"1px solid #D2D2D0"}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "#333",
                    lineHeight: "15px",
                    textTransform: "capitalize",
                  }}
                >
                  {`${profileData.first_name} ${profileData.last_name}`}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "#696969",
                    lineHeight: "15px",
                  }}
                  noWrap={true}
                >
                  {profileData.email}
                </Typography>
              </Box>
              {menuData.map((item, id) => {
                return (
                  <Box
                    key={id}
                    paddingX={"12px"}
                    paddingY={"8px"}
                    display={"flex"}
                    flexDirection={"row"}
                    gap={"8px"}
                    alignItems={"center"}
                    borderBottom={
                      id != menuData.length - 1 ? "1px solid #D2D2D0" : "0px"
                    }
                    sx={{ cursor: "pointer" }}
                    onClick={item.onClick}
                  >
                    <Icons width={20} height={20} name={item.icon} />
                    <Typography color={"#333333"} variant="bodySm">
                      {item.label}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Popover>
        </Box>
      </Box>
      <Settings
        open={openPopup}
        handleClose={handlePopupClose}
        resetPass={resetPass}
        setResetPass={setResetPass}
        setPassUpdatePopup={setPassUpdatePopup}
        fetchData={fetchData}
        fName={profileData?.first_name}
        lName={profileData?.last_name}
        setToast={setToast}
      />
      <FileUpload
        open={open}
        handleClose={handleClose}
        uploadingFile={uploadingFile}
        setUploadingFile={setUploadingFile}
      />
      {drawer1 && (
        <BugList
          bugDetail={bugDetail}
          setBugDetail={setBugDetail}
          bugDeviceDetail={bugDeviceDetail}
          setBugDeviceDetail={setBugDeviceDetail}
          handleBugClick={handleBugClick}
          drawer={drawer1}
          setDrawer={setDrawer1}
          bug={bug}
          setBug={setBug}
        />
      )}
      <Modal
        open={passUpdatePopup}
        disableEscapeKeyDown={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #FFA737",
            boxShadow: 24,
            borderRadius: "8px",
            p: 4,
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ color: "#007634" }}
          >
            Password successfully updated!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Please login again to access your account.
          </Typography>
        </Box>
      </Modal>
      {toast && (
        <ToastMessage
          type={toast.type as "success" | "error"}
          message={toast.message}
          onClose={() => setToast(null)}
          style={{
            position: "absolute",
            top: "85px",
          }}
        />
      )}
    </>
  );
};

export default Header;
