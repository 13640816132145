import { AxiosError, AxiosProgressEvent, AxiosResponse } from "axios";
import client from "./apiClientFileUpload";

export const uploadFileAPi = async (
  payload: object,
  token: string | null,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
) =>
  await client.post("/files/upload-file/v2", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: onUploadProgress,
  });
export const fileMergeAPi = async (
  payload: object,
  token: string | null,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
) =>
  await client.post("/files/merge-chunks", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: onUploadProgress,
  });
// export const uploadFileAPiChunks = async (payload: object, token: string | null,onUploadProgress?: (progressEvent: AxiosProgressEvent) => void) =>
//   await client.post("/files/chunk-upload", payload, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//       "Content-Type": "multipart/form-data",
//     },
//     onUploadProgress:onUploadProgress
//   });

// export const uploadFileAPiChunks = async (
//   payload: object,
//   token: string | null,
//   onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
// ) =>
//   await client.post("/files/chunk-upload", payload, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//       "Content-Type": "multipart/form-data",
//     },
//     onUploadProgress: onUploadProgress,
//   });
export const uploadFileAPiChunks = async (
  payload: object,
  token: string | null,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
): Promise<AxiosResponse> => {
  const maxRetries = 3; // Maximum retry attempts
  let attempt = 0;

  while (attempt < maxRetries) {
    try {
      // ✅ API call and return the full Axios response
      const response = await client.post("/files/chunk-upload", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: onUploadProgress,
      });

      return response; // ✅ Correctly returning full response
    } catch (error) {
      const axiosError = error as AxiosError;

      // ✅ Retry only if network error (timeout or no response)
      if (
        axiosError.code === "ECONNABORTED" || // Timeout
        !axiosError.response // No response (network issue)
      ) {
        attempt++;

        if (attempt < maxRetries) {
          // ⏳ Exponential backoff before retrying
          const delay = Math.pow(2, attempt) * 1000;
          console.warn(
            `Retry attempt ${attempt}/${maxRetries} in ${delay / 1000} seconds...`
          );
          await new Promise((resolve) => setTimeout(resolve, delay));
        } else {
          console.error("Max retries reached. Upload failed.");
          throw error; // ❌ Stop retrying after max attempts
        }
      } else {
        throw error; // ❌ If it's another error, do not retry
      }
    }
  }

  throw new Error("Unexpected error: function reached an invalid state.");
};
// export const uploadFileAPiChunks = async (
//   payload: object,
//   token: string | null,
//   onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
// ) =>
//   await client.post("/files/chunk-upload", payload, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//       "Content-Type": "multipart/form-data",
//     },
//     onUploadProgress: onUploadProgress,
//   });

export const uploadCRFileAPiChunks = async (
  payload: object,
  token: string | null,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
) =>
  await client.post("/class_recording/chunk-upload-v1", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: onUploadProgress,
  });
export const uploadFileAPiChunkstos3 = async (
  urlVal: string,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
) =>
  await client.put(urlVal, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: onUploadProgress,
  });
  
  export const initiateMultipartUpload = async (
    fileName: string,
    token: string | null,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
  ): Promise<AxiosResponse> => {
    const maxRetries = 3;
    let attempt = 0;
  
    while (attempt < maxRetries) {
      try {
        const response = await client.get(
          `/files/initiateMultipartUpload?fileName=${encodeURIComponent(fileName)}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: onUploadProgress,
          }
        );
        return response; // ✅ Always return on success
      } catch (error) {
        const axiosError = error as AxiosError;
  
        if (axiosError.code === "ECONNABORTED" || !axiosError.response) {
          attempt++;
          if (attempt < maxRetries) {
            const delay = Math.pow(2, attempt) * 1000;
            console.warn(
              `Retry attempt ${attempt}/${maxRetries} in ${delay / 1000} seconds...`
            );
            await new Promise((resolve) => setTimeout(resolve, delay));
          }
        } else {
          throw error; // ✅ If it's a non-retryable error, throw immediately
        }
      }
    }
  
    // ✅ Throw an error if all retries fail
    throw new Error("Max retries reached. Upload failed.");
  };

export const duplicateFileCheck = async (
  payload: object,
  token: string | null,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
) =>
  await client.post("/files/validateDuplicateFile", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    onUploadProgress: onUploadProgress,
  });
export const duplicateCRFileCheck = async (
  payload: object,
  token: string | null,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
) =>
  await client.post("/class_recording/validateDuplicateFile", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    onUploadProgress: onUploadProgress,
  });
