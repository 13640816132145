
import React, { useState, useEffect } from "react";
import { Skeleton } from "@mui/material";
import TableComponent from "./tableComponent";
import Cookies from "js-cookie";
// import { getStudentSubject } from "../../services/api/student";
import { getUserList } from "../../services/api/user";
 
import { useSelector } from "react-redux";
import { RootState } from "../../store";
 
type Student = {
  lastAccessed: any;
  last_access_date: any;
  time_spent: any;
  id: string;
  mst_student_id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  access_start_date: string | null;
  access_end_date: string | null;
  access_status: boolean;
  campus_id: string;
};
 
type TableData = {
  lastAccessed: any;
  last_access_date: any;
  time_spent: any;
  id: number;
  sub_code: string;
  label: string;
  students: Student[];
};
 
export const UsersComponent = () => {
  const verifyAccessString = Cookies.get("authAccess");
  const { shouldRefetch } = useSelector((state: RootState) => state.studentLibrary);
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;
 
  const [selectedTab] = useState("Student"); // This is the state that controls tab selection
  const [stuData, setStuData] = useState<TableData[]>([]);
  const [loading, setLoading] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
  });
  const [tableLoading, setTableLoading] = useState(true);
  const [totallCount, setTotallCount] = useState<string>("0");
  const [rowCountState, setRowCountState] = useState(totallCount);
 
  const handleSelectionChange = (count: number, countData: number[]) => {
    console.log("Selected Count: ", count);
    console.log("Selected Data: ", countData);
  };
 
  const fetchData = async () => {
    try {
      setTableLoading(true);
      const response = await getUserList(
        verifyAccess.token,
        paginationModel.page,
        paginationModel.pageSize,

      );

      if (!response.data.users || response.data.users.length === 0) {
        setStuData([]);
      } else {
        setStuData(response.data.users);
        setTotallCount(response.data.totalCount);
      }
      setLoading(false);
      setTableLoading(false);
    } catch (error) {
      setLoading(false);
      setTableLoading(false);
      console.error("Error fetching data:", error);
    }
  };
 
  useEffect(() => {
    fetchData(); // Pass selectedTab to fetch data accordingly
  }, [ paginationModel]);
 
  useEffect(() => {
    shouldRefetch !== null && fetchData(); // Fetch data when the flag changes
  }, [shouldRefetch]);
 
  useEffect(() => {
    setRowCountState((prevRowCountState: any) =>
      totallCount !== undefined ? totallCount : prevRowCountState
    );
  }, [totallCount,setRowCountState]);

  return (
    <>
      {loading ? (
        <Skeleton
          variant="rounded"
          width={"100%"}
          height={"calc(100vh - 330px)"}
        />
      ) : (
        <TableComponent
          selectedTab={selectedTab}
          stuData={stuData}
          loading={loading}
          fetchData={fetchData}
          tableLoading={tableLoading}
          rowCountState={rowCountState}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          handleSelectionChange={handleSelectionChange}
        />
      )}
    </>
  );
};
 
 