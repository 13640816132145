import React, { useState,useEffect } from "react";
import { Box, Typography, FormControl, Select, MenuItem, TextField, Grid, SelectChangeEvent } from "@mui/material";
import { getProfile } from "../../services/api/profile";
import { getRoleList } from "../../services/api/roles";

import Cookies from "js-cookie";



// Defining the interface for the student data.
interface StudentData {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  role_id?: string;
  password?:string;
  added_by?:string;
}

interface ProfileData {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  is_verified: boolean;
  record_status: string;
  role_id: string;
}

interface AddNewStudentComponentProps {
  isAddingStudent: boolean;
  updateStudentData: (data: Partial<StudentData>) => void;
}


const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;





const AddNewStudentComponent: React.FC<AddNewStudentComponentProps> = ({
  isAddingStudent,
  updateStudentData,
}) => {
  const [role_id, setrole_id] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [phone, setphone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

const [,
    setIsEmailValid,
  ] = React.useState(true);
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    const { id, value } = event.target;
    const input = event.target.value;
    if (id === "first_name") {
      updateStudentData({ first_name: value });
    } else {
      updateStudentData({ last_name: value });
    }
    if (/^[a-zA-Z]+$/.test(input) || input === "") {
      setter(input);
    }
  };
  const handleInputNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<string>>,
    isNumeric: boolean = false,
  ) => {
    let input = event.target.value;
    updateStudentData({ phone: input });
    if (isNumeric) {
      input = input.replace(/\D/g, "");
    }
    setter(input);
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputEmail = event.target.value;
    const isValid = /\S+@\S+\.\S+/.test(inputEmail);
    setEmail(inputEmail);
    setIsEmailValid(isValid);
    updateStudentData({ email: inputEmail });
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputPassword = event.target.value;
  
    // Password validation: at least one uppercase, one lowercase, one number, and one special character
    const isValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(inputPassword);
    
    setPassword(inputPassword);
    setIsEmailValid(isValid);
    updateStudentData({ password: inputPassword });
  };
  
  
  const [profileData, setProfileData] = useState<ProfileData | null>(null);
  const [getrole, setrole] = useState<ProfileData | null>(null);
console.log("getrole----------",getrole);

const fetchData = async () => {
    try {
      const response = await getProfile(verifyAccess.token);
      setProfileData(response.data.data[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
  });
console.log("paginationModel",setPaginationModel);
const getRoleListdata = async () => {
  try {
    const response = await getRoleList(
      verifyAccess.token,
      paginationModel.page,
      paginationModel.pageSize,);
      setrole(response.data.data[0]);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};


  useEffect(() => {
    fetchData();
    getRoleListdata();
  }, []);



  const handleChange = (event: SelectChangeEvent) => {
    setrole_id(event.target.value as string);
    updateStudentData({ role_id: (event.target.value as string) });
    updateStudentData({ password: "" });
    updateStudentData({ added_by: (profileData?.id as unknown as string ) });
  };
  
  return (
    <Box>
      {isAddingStudent ? (
        <Box>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Typography color={"#333333"} variant="detailBold" sx={{ marginBottom: "4px" }}>First name</Typography>
              <TextField
                id="first_name"
                value={first_name}
                 onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                  handleInputChange(event, setfirst_name)
                                }
                variant="outlined"
                sx={{
                  width: "100%",
                  color: "#333333",
                  ".MuiInputBase-input": {
                    color: "#333333",
                    paddingX: "16px",
                    paddingY: "13px",
                    fontSize: "14px",
                    fontWeight: "400",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                    border: "1px solid #D2D2D0",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <Typography color={"#333333"} variant="detailBold" sx={{ marginBottom: "4px" }}>Last name</Typography>
              <TextField
                id="last_name"
                value={last_name}
                 onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                  handleInputChange(event, setlast_name)
                                }
                variant="outlined"
                sx={{
                  width: "100%",
                  color: "#333333",
                  ".MuiInputBase-input": {
                    color: "#333333",
                    paddingX: "16px",
                    paddingY: "13px",
                    fontSize: "14px",
                    fontWeight: "400",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                    border: "1px solid #D2D2D0",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <Typography color={"#333333"} variant="detailBold" sx={{ marginBottom: "4px" }}>Phone Number</Typography>
              <TextField
                id="phone"
                value={phone}
                 onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                  handleInputNumberChange(event, setphone, true)
                                }
                variant="outlined"
                sx={{
                  width: "100%",
                  color: "#333333",
                  ".MuiInputBase-input": {
                    color: "#333333",
                    paddingX: "16px",
                    paddingY: "13px",
                    fontSize: "14px",
                    fontWeight: "400",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                    border: "1px solid #D2D2D0",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <Typography color={"#333333"} variant="detailBold" sx={{ marginBottom: "4px" }}>Email</Typography>
              <TextField
                id="email"
                value={email}
                onChange={handleEmailChange}
                onInput={(e) => (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.replace(/\s/g, '')}  // Prevent spaces
                variant="outlined"
                sx={{
                  width: "100%",
                  color: "#333333",
                  ".MuiInputBase-input": {
                    color: "#333333",
                    paddingX: "16px",
                    paddingY: "13px",
                    fontSize: "14px",
                    fontWeight: "400",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                    border: "1px solid #D2D2D0",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <Typography color={"#333333"} variant="detailBold" sx={{ marginBottom: "4px" }}>Role</Typography>
              <FormControl sx={{ width: "100%" }}>
                <Select
                id="role_id"
                  value={role_id}
                  onChange={handleChange}
                  displayEmpty
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#333333",
                    borderRadius: "8px",
                    minWidth: "184px",
                    textTransform: "capitalize",
                    ".MuiSelect-select": {
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#333333",
                      paddingX: "16px",
                      paddingY: "13px",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #D2D2D0",
                    },
                  }}
                >
                  <MenuItem value="" disabled sx={{ display: 'none' }}>Select any</MenuItem>
                  <MenuItem value="1">L1</MenuItem>
                  <MenuItem value="1">L2</MenuItem>
                  <MenuItem value="1">L3</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <Typography color={"#333333"} variant="detailBold" sx={{ marginBottom: "4px" }}>Password</Typography>
              <TextField
                id="password"
                value={password}
                onChange={(e) => handlePasswordChange(e)}  
                onInput={(e) => (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.replace(/\s/g, '')}  // Prevent spaces
                variant="outlined"
                sx={{
                  width: "100%",
                  color: "#333333",
                  ".MuiInputBase-input": {
                    color: "#333333",
                    paddingX: "16px",
                    paddingY: "13px",
                    fontSize: "14px",
                    fontWeight: "400",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                    border: "1px solid #D2D2D0",
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box>
          <Typography sx={{ paddingY: "16px" }} color="#333333" variant="title4" component={"div"}>Add new user</Typography>
        </Box>
      )}
    </Box>
  );
};

export default AddNewStudentComponent;
