// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Inter-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/Inter-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/Inter-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/fonts/Inter-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./assets/fonts/Inter-SemiBold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./assets/fonts/Inter-SemiBold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./assets/fonts/PolySans/PolySans-Neutral.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./assets/fonts/PolySans/PolySans-Median.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("./assets/fonts/PolySans/PolySans-Bulky.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Inter";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: "PolySans_Neutral";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("woff");
  /* font-weight: 600; */
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: "PolySans_Median";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format("woff");
  /* font-weight: 600; */
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: "PolySans_Bulky";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format("woff");
  /* font-weight: 600; */
  font-style: normal;
  font-display: swap;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB;0DACsD;EACtD,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;EACpB;0DACyD;EACzD,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;EACpB;0DAC0D;EAC1D,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;AACpB;;;AAGA;EACE,+BAA+B;EAC/B,2DAAwE;EACxE,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;AACpB;;;AAGA;EACE,8BAA8B;EAC9B,2DAAuE;EACvE,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;AACpB;;;AAGA;EACE,6BAA6B;EAC7B,2DAAsE;EACtE,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":["@font-face {\n  font-family: \"Inter\";\n  src: url(\"./assets/fonts/Inter-Bold.woff2\") format(\"woff2\"),\n    url(\"./assets/fonts/Inter-Bold.woff\") format(\"woff\");\n  font-weight: bold;\n  font-style: normal;\n  font-display: swap;\n}\n\n@font-face {\n  font-family: \"Inter\";\n  src: url(\"./assets/fonts/Inter-Regular.woff2\") format(\"woff2\"),\n    url(\"./assets/fonts/Inter-Regular.woff\") format(\"woff\");\n  font-weight: normal;\n  font-style: normal;\n  font-display: swap;\n}\n\n@font-face {\n  font-family: \"Inter\";\n  src: url(\"./assets/fonts/Inter-SemiBold.woff2\") format(\"woff2\"),\n    url(\"./assets/fonts/Inter-SemiBold.woff\") format(\"woff\");\n  font-weight: 600;\n  font-style: normal;\n  font-display: swap;\n}\n\n\n@font-face {\n  font-family: \"PolySans_Neutral\";\n  src: url(\"./assets/fonts/PolySans/PolySans-Neutral.woff\") format(\"woff\");\n  /* font-weight: 600; */\n  font-style: normal;\n  font-display: swap;\n}\n\n\n@font-face {\n  font-family: \"PolySans_Median\";\n  src: url(\"./assets/fonts/PolySans/PolySans-Median.woff\") format(\"woff\");\n  /* font-weight: 600; */\n  font-style: normal;\n  font-display: swap;\n}\n\n\n@font-face {\n  font-family: \"PolySans_Bulky\";\n  src: url(\"./assets/fonts/PolySans/PolySans-Bulky.woff\") format(\"woff\");\n  /* font-weight: 600; */\n  font-style: normal;\n  font-display: swap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
