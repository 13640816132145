import {
  Add,
  BookOpen,
  BugIcon,
  FolderOpen,
  Home,
  Logout,
  Notification,
  ExpandVideoFull,
  Forward15,
  PauseSmallIcon,
  PlayIconCircle,
  PlaySmallIcon,
  RePlay15,
  SkipPrevious,
  SkipNext,
  Settings,
  Student,
  UserArrowDown,
  Filter,
  TextFile,
  Description,
  Bug,
  Warning,
  Minus,
  Search,
  Close,
  ChevronLeft,
  Undo,
  UndoDark,
  ChevronRight,
  Recording,
  Show,
  Hide,
  WarningRed,
  Edit,
  CheckCircle,
  Cancel,
  GalleryIcon,
  RecordingShow,
  Trash,
  OutLineBook,
  Info,
  CheckTick,
  Export,
  Mail,
  Phone,
  Upload,
  Replace,
  ChevronDown,
  ChevronUp,
  Expand,
  PdfIcon,
  DescIcon,
  LockReset,
  users,
} from "../../utils/icons";
type IconComponentProps = {
  name: string;
  fill?: string;
  width?: number;
  height?: number;
  style?: object;
  onClick?: () => void;
};
type IconTypes = { [name: string]: ReactSVGComponent };

const iconTypes: IconTypes = {
  home: Home,
  folderOpen: FolderOpen,
  bookOpen: BookOpen,
  student: Student,
  add: Add,
  notification: Notification,
  userArrowDown: UserArrowDown,
  settings: Settings,
  bugIcon: BugIcon,
  logout: Logout,
  filter: Filter,
  textFile: TextFile,
  expandVideoFull: ExpandVideoFull,
  forward15: Forward15,
  pauseSmallIcon: PauseSmallIcon,
  playIconCircle: PlayIconCircle,
  playSmallIcon: PlaySmallIcon,
  rePlay15: RePlay15,
  skipPrevious: SkipPrevious,
  skipNext: SkipNext,
  description: Description,
  bug: Bug,
  warning: Warning,
  minus: Minus,
  search: Search,
  close: Close,
  chevronLeft: ChevronLeft,
  undo: Undo,
  undoDark: UndoDark,
  chevronRight: ChevronRight,
  recording: Recording,
  show: Show,
  hide: Hide,
  warningRed: WarningRed,
  edit: Edit,
  checkCircle: CheckCircle,
  cancel: Cancel,
  galleryIcon: GalleryIcon,
  recordingShow: RecordingShow,
  trash: Trash,
  outLineBook: OutLineBook,
  info: Info,
  checkTick: CheckTick,
  export: Export,
  mail: Mail,
  phone: Phone,
  upload: Upload,
  replace: Replace,
  chevronDown: ChevronDown,
  chevronUp: ChevronUp,
  expand: Expand,
  pdfImage:PdfIcon,
  descImage:DescIcon,
  lockReset:LockReset,
  users:users,
};

const Icons = ({ name, ...props }: IconComponentProps) => {
  const Icons = iconTypes[name];
  return <Icons {...props} />;
};

export default Icons;
