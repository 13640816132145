import { Box, Typography, Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";
import Tab from "../Tab";
import BorderText from "../Label/BorderText";
import StatusDropdown from "../Dropdown/StatusDropdown";
import BugList from "./BugList";
import Cookies from "js-cookie";
import { getBugListData, viewBugDetail } from "../../services/api/dashboard";
import ToastMessage from "../ToastMessage";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

type DataBug = {
  id: number;
  bug_title: string;
  bug_description: string;
  attachment: string;
  file_id: number;
  file_name: string;
  record_status: string;
  area_of_study_id: number;
  aos_name: string;
  reported_in: string;
  createdAt: string;
  updatedAt: string;
};

export interface BugDevice {
  browser: string;
  browserVersion: string;
  device: string;
  os: string; 

}
export interface BugDetailState {
  id: number;
  bug_title: string;
  bug_description: string;
  speed: string;
  browser_info: string;
  attachment: string;
  file_id: number;
  file_name: string;
  file_type_id: number;
  file_type_name: string;
  area_of_study_id: number;
  area_of_study_name: string;
  subject_id: number;
  subject_name: string;
  topic_id: number;
  topic_name: string;
  sub_grp_id: number;
  sub_grp_name: string;
  sub_grp_code: string;
  student_id: number;
  reported_by: string;
  record_status: string;
  createdAt: string;
  updatedAt: string;
}

const BugReport = () => {
  const {shouldRefetch}=useSelector((state:RootState)=>state.bugLibrary)
  const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;
  const [tab, setTab] = useState(0);
  const [drawer, setDrawer] = useState(false);
  const [bugData, setBugData] = useState<DataBug[]>([]);
  const [loading, setLoading] = useState(true);
  const [bug, setBug] = useState("");
  const [toast, setToast] = React.useState<{
    type: string;
    message: string;
  } | null>(null);
  const [bugDeviceDetail, setBugDeviceDetail] = useState<BugDevice>({
    browser: "",
    browserVersion: "",
    device: "",
    os: "",
  });
  const [bugDetail, setBugDetail] = useState<BugDetailState>({
    id: 0,
    bug_title: "",
    bug_description: "",
    speed:"",
    browser_info: "",
    attachment: "",
    file_id: 0,
    file_name: "",
    file_type_id: 0,
    file_type_name: "",
    area_of_study_id: 0,
    area_of_study_name: "",
    subject_id: 0,
    subject_name: "",
    topic_id: 0,
    topic_name: "",
    sub_grp_id: 0,
    sub_grp_name: "",
    sub_grp_code: "",
    student_id: 0,
    reported_by: "",
    record_status: "",
    createdAt: "",
    updatedAt: "",
  });
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();

    if (
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear()
    ) {
      const options: Intl.DateTimeFormatOptions = {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };
      return `Today, ${date.toLocaleString("en-US", options)}`;
    }

    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    if (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    ) {
      const options: Intl.DateTimeFormatOptions = {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };
      return `Yesterday, ${date.toLocaleString("en-US", options)}`;
    }

    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const [bugTabs, setBugTabs] = useState([
    {
      label: "Needs attention",
      value: "needs attention",
      count: 0,
    },
    {
      label: "In review",
      value: "in review",
      count: 0,
    },
  ]);

  useEffect(() => {
    fetchBugData();
  }, []);
  useEffect(() => {
    shouldRefetch!==null && fetchBugData();
  }, [shouldRefetch]);

  const fetchBugData = async () => {
    setLoading(true);
    try {
      const response = await getBugListData(verifyAccess.token);
      if (!response.data.success || response.data.data.length === 0) {
        setBugData([]);
        updateBugTabsCount([]);
        setLoading(false);
      } else {
        setBugData(response.data.data);
        updateBugTabsCount(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const updateBugTabsCount = (bugList: DataBug[]) => {
    const updatedTabs = bugTabs.map((tab) => ({
      ...tab,
      count: bugList.filter(
        (bug) => bug.record_status.toLowerCase() === tab.value
      ).length,
    }));
    setBugTabs(updatedTabs);
  };

  const reports = bugData?.filter(
    (i) => i.record_status.toLowerCase() == bugTabs[tab].value
  );
  const sortedBugData = reports?.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });
  const handleBug = (val: string) => {
    console.log(val);
  };

  const fetchBugDetailData = async (id: number) => {
    try {
      const response = await viewBugDetail(verifyAccess.token, id);
      if(response.status){
        setBugDetail(response.data?.data||[]);
        setBugDeviceDetail(response.data?.data?.deviceDetails||[]);

      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleBugClick = (id: number) => {
    fetchBugDetailData(id);
  };

  return (
    <>
      {toast && (
        <ToastMessage
          type={toast.type as "success" | "error"}
          message={toast.message}
          onClose={() => setToast(null)}
          style={{
            position: "abosolute",
            top: "90px",
            textTransform: "capitalize",
          }}
        />
      )}

      <Box
        sx={{
          bgcolor: "white",
          padding: "28px",
          borderRadius: "16px",
          flexGrow: 1,
          gap: "24px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography color={"contour.title4"} variant="title4">
            Bug Reports
          </Typography>
          <BorderText text={"View All"} onClick={() => setDrawer(true)} />
        </Box>
        {loading ? (
          <Skeleton
            variant="rounded"
            width={"100%"}
            height={"calc(100vh - 300px)"}
          />
        ) : (
          <>
            <Box>
              <Tab tabData={bugTabs} selected={tab} setSelectedTab={setTab} />
            </Box>
            <Box
              sx={{
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                marginRight: "-10px",
                height: "400px",
              }}
            >
              {sortedBugData.length > 0 ? (
                sortedBugData?.map((item) => {
                  return (
                    <Box
                      key={item.id}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "stretch",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #EFEFEF",
                      }}
                    >
                      <Box
                        width={"65%"}
                        sx={{ paddingY: "15px", cursor: "pointer" }}
                        display={"flex"}
                        flexDirection={"column"}
                        onClick={() => {
                          fetchBugDetailData(item.id);
                          setBug(item.id.toString());
                          setDrawer(true);
                        }}
                      >
                        <Typography color={"#000000"} variant="bodySm">
                          {item.reported_in}
                        </Typography>
                        <Typography color={"#696969"} variant="bodySm">
                          {formatDate(item.createdAt)}
                        </Typography>
                      </Box>
                      <StatusDropdown
                        sx={{ width: "31%", whiteSpace: "nowrap" }}
                        status={item.record_status.toLowerCase()}
                        type={"bugs"}
                        setToast={setToast}
                        fileId={item.id}
                        timestamp={""}
                        onClickCallback={handleBug}
                      />
                    </Box>
                  );
                })
              ) : (
                <Box>No data</Box>
              )}
            </Box>
          </>
        )}
        <BugList
          bugDetail={bugDetail}
          setBugDetail={setBugDetail}
          bugDeviceDetail={bugDeviceDetail}
          setBugDeviceDetail={setBugDeviceDetail}
          handleBugClick={handleBugClick}
          drawer={drawer}
          setDrawer={setDrawer}
          bug={bug}
          setBug={setBug}
        />
      </Box>
    </>
  );
};

export default BugReport;
