import React from "react";
import Snackbar from "@mui/material/Snackbar";
import { styled } from "@mui/system";
import IconComponent from "../Icons";
import { Box, Typography } from "@mui/material";

const StyledSnackbar = styled(Snackbar)(() => ({
  "&.MuiSnackbar-root": {
    top: "15%",
    borderRadius: "4px",
    width: "361px !important",
    padding: "12px 8px !important",
    display: "block !important",
    // zIndex: "1 !important",
  },
  "&.MuiSnackbar-root .css-10akmkd-MuiPaper-root-MuiAlert-root": {
    width: "361px !important",
    padding: "8px 12px !important",
  },
  "&.MuiSnackbar-root .css-1pxa9xg-MuiAlert-message": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px !important",
  },
}));

type ToastProps = {
  type: "success" | "error";
  message: string;
  onClose: () => void;
  style?: object;
};

const ToastMessage: React.FC<ToastProps> = ({
  type,
  message,
  onClose,
  style,
}) => {
  return (
    <StyledSnackbar
      open
      autoHideDuration={5500}
      onClose={onClose}
      className={type}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{
        background: type === "success" ? "#E6F6ED" : "#FFEEF2",
        border: type === "success" ? "1px solid #007634" : "1px solid #C61341",
      }}
      style={style}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex" }}>
          {type === "success" ? (
            <IconComponent
              name="checkCircle"
              fill={"#007634"}
              style={{ marginRight: "12px" }}
            />
          ) : (
            <IconComponent
              name="cancel"
              fill={"#C61341"}
              style={{ marginRight: "12px" }}
            />
          )}
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "600",
              color: "#181A20",
            }}
          >
            {message}
          </Typography>
        </Box>
        <Box onClick={onClose} sx={{ cursor: "pointer" }}>
          {type === "success" ? (
            <IconComponent name="close" fill={"#007634"} />
          ) : (
            <IconComponent name="close" fill={"#C61341"} />
          )}
        </Box>
      </Box>
    </StyledSnackbar>
  );
};

export default ToastMessage;
