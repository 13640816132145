import React from "react";
import Dashboard from "../../layouts/Dashboard";
import { Box, Typography } from "@mui/material";
import BugReport from "../../components/BugReport";
import Files from "../../components/Files";


export const AdminDashboard = () => {
 
  return (
    <Dashboard>
      <Box display={"flex"} flexDirection={"column"} flexGrow={1} gap={"24px"}>
      <Typography color={"contour.title2"} variant="title2" component={"div"}>
          Dashboard
        </Typography>
        

        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={"24px"}
          width={"100%"}
          flexGrow={1}
        >
          <Box
            width={"40%"}
            display={"flex"}
            flexGrow={1}
            flexDirection={"column"}
            position={"relative"}
          >
            <BugReport />
          </Box>
          <Box
            width={"60%"}
            display={"flex"}
            flexGrow={1}
            flexDirection={"column"}
            position={"relative"}
          >
            <Files />
          </Box>
        </Box>
      </Box>
    </Dashboard>
  );
};
