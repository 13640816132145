import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Icons from "../Icons";
import RightDrawer from "../Drawer";
import Cookies from "js-cookie";
import {
  getNotificationData,
  notificationReadUpdate,
  viewBugDetail,
} from "../../services/api/dashboard";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import BugDetail from "../BugReport/BugDetail";
import FileDetail from "../FileLibrary/fileDetails";
const Notification: React.FC<{
  drawer: boolean;
  setDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  passData: any;
}> = ({ drawer, setDrawer, passData }) => {
  const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;
  const navigate = useNavigate();
  const [notificationData, setNotificationData] = useState<any[]>([]);
  const [selectedRowData, setSelectedRowData] = React.useState<number>();
  const [openViewFile, setOpenViewFile] = useState(false);
  const [bugDetail, setBugDetail] = useState<any | null>(null);
  const [bugDeta, setBugDeta] = useState<boolean>(false);
  const fetchNotifyData = async () => {
    try {
      const response = await getNotificationData(verifyAccess.token);
      setNotificationData(response.data.data);
      passData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchNotifyData();
  }, []);
  const handleNavigate = () => {
    navigate("/dashboard");
  };
  const token = verifyAccess.token;

  const handleChange = async (id: number) => {
    try {
      const payload: {
        notificationIds: number[];
      } = {
        notificationIds: [id],
      };

      const response = await notificationReadUpdate(payload, token);
      if (response && response.data) {
        const updatedData = notificationData.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              is_read: true,
            };
          }
          return item;
        });
        setNotificationData(updatedData);
        const successMessage = response.data.message;
        console.log(successMessage);
      } else {
        const errorMessage =
          response && response.data.message
            ? response.data.message
            : "An error occurred";
        console.log(errorMessage);
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleBugReportClick = async (id: number) => {
    const selectedNotification = notificationData.find(
      (item) => item.id === id
    );
    if (selectedNotification?.notification_type === "bug_report") {
      try {
        const response = await viewBugDetail(
          verifyAccess.token,
          selectedNotification?.meta_data?.id
        );
        setBugDetail(response.data.data);
        setDrawer(false);
        setBugDeta(true);
      } catch (error) {
        console.error("Error fetching bug detail data:", error);
      }
    }

    handleChange(id);
  };
  const handleViewFile = () => {
    setSelectedRowData(bugDetail.file_id);
    setDrawer(false)
    setOpenViewFile(true);
    setBugDeta(false)
  };

  return (
    <>
      <RightDrawer
        open={drawer}
        handleClose={() => setDrawer(false)}
        title="Notification"
      >
        {notificationData.map((item, index) => {
          return (
            <Box
              key={index}
              onClick={() => handleBugReportClick(item.id)}
              sx={{
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
                alignItems: "center",
                borderBottom: "1px solid #EFEFEF",
                background: item.is_read ? "#FFFF" : "#F4F6FF",
              }}
            >
              <Box
                sx={{
                  padding: "15px",
                  width: "62%",
                }}
                display={"flex"}
                alignItems={"center"}
              >
                {item.notification_type === "bug_report" && (
                  <Icons
                    name="bug"
                    fill="#C61341"
                    width={20}
                    height={20}
                    style={{ marginRight: "8px" }}
                  />
                )}
                {item.notification_type === "missing_file" && (
                  <Icons
                    name="warning"
                    fill="#C61341"
                    width={20}
                    height={20}
                    style={{ marginRight: "8px" }}
                  />
                )}
                <Typography color={"#000000"} variant="bodySm">
                  {item.message}
                </Typography>
              </Box>
              <Box
                sx={{
                  padding: "15px",
                  width: "38%",
                }}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box>
                  <Typography color={"#696969"} variant="bodySm">
                    {moment(moment.utc(item?.updated_at).toDate()).local()
                      .fromNow()}
                  </Typography>
                </Box>
                <Box onClick={handleNavigate} sx={{ cursor: "pointer" }}>
                  <Icons
                    name="chevronRight"
                    fill="#8E8D8A"
                    style={{ marginRight: "8px" }}
                  />
                </Box>
              </Box>
            </Box>
          );
        })}
      </RightDrawer>

      <RightDrawer
        open={bugDeta}
        handleClose={() => setBugDeta(false)}
        title="Bug Reports"
        btnText="View File"
        handleButton={() => handleViewFile()}
        extra={"bug"}
      >
        <BugDetail
          bugDetData={bugDetail}
          goBack={true} bugDeviceDetail={{
            device: <></>,
            os: <></>,
            browser: "",
            browserVersion: "",
          }}        />
      </RightDrawer>
      <FileDetail
        drawer={openViewFile}
        setDrawer={setOpenViewFile}
        selectedRowData={selectedRowData}
        isEditing={false}
      />
    </>
  );
};

export default Notification;
