import React from "react";
import {
  Box,
  Typography,
  Link,
  Accordion,
  TextField,
  AccordionSummary,
  AccordionDetails,
  LinearProgress,
  Button,
} from "@mui/material";
import Icons from "../Icons";
import CircularProgressScreen from "./CircularProgress";
import UploadImg from "../../assets/images/upload.png";
import { getTimeFormat } from "../../utils/utilsfuns";

type File = {
  name: string;
  deleted?: boolean;
  type: string;
  size: number;
  lastModified: number;
  edited?: boolean;
  status?:{type:"success"|"failure",message:string}
};

type Batch = {
  files: File[];
  isPublished: boolean;
  filesAdd?: File[];
  addedToBatch?: boolean;
  uploadStatus: string[];
};

interface UploadAccordionProps {
  index: number;
  uploadProgress: number;
  uploadSpeed: any;
  uploadedStatus: boolean[];
  batchData: Batch;
  publishedDateTime: string[];
  invalidTypeAdd: boolean;
  invalidBatchIndexes: number[];
  isUploading: boolean;
  batchProgress: number[];
  batchLoaders: boolean[];
  batches: Batch[];
  UploadStatus: {
    INITIAL:string,
    WAITING:string,
    Uploading:string,
    COMPRESSING:string,
    GENERATING:string,
    ENCRYPTING:string,
    COMPLETED:string,
    FAILED:string,
  };
  uploadingBatchIndex: number | null;
  setShowFileList: React.Dispatch<React.SetStateAction<boolean>>;
  setUploadingFile: (newState: boolean) => void;
  draggedBatchIndex: number | undefined;
  editingAddIndexes: Record<number, number | null>;
  setBatches: React.Dispatch<React.SetStateAction<Batch[]>>;
  currentBatchIndex: number;
  replacedFiles: Record<number, boolean>;
  handleFileChange1: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  handleFileClick: (id:string) => void;
  handleRetry: (id:number,currentBatchIndex:number) => void;
  cancelUpload: {
    isOpen: boolean;
    batchIndex: number | null;
  };
  fileProgress: number[];
  handleDrop1: (
    e: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => Promise<void>;
  handleAddMoreDragLeave: () => void;
  handleAddMoreDragEnter: (
    e: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => void;
  handleAddToBatch: (batchIndex: number) => Promise<void>;
  handleCancelEditFileName1: (batchIndex: number) => void;
  handleUndoDelete1: (batchIndex: number, fileIndex: number) => void;
  handleDeleteFile1: (batchIndex: number, fileIndex: number) => void;
  handleEditFileName1: (batchIndex: number, fileIndex: number) => void;
  handleSaveFileName1: (batchIndex: number, fileIndex: number) => void;
  editedAddFileNames: Record<
    number,
    Record<number, { edited: string; original: string }>
  >;
  editedFileNames: Record<
    number,
    Record<number, { edited: string; original: string }>
  >;
  setEditedAddFileNames: React.Dispatch<
    React.SetStateAction<
      Record<number, Record<number, { edited: string; original: string }>>
    >
  >;
  setCancelUpload: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      batchIndex: number | null;
    }>
  >;
  setCurrentBatchIndex: React.Dispatch<React.SetStateAction<number>>;
}

const UploadAccordion: React.FC<UploadAccordionProps> = ({
  index,
  uploadSpeed,
  uploadProgress,
  uploadedStatus,
  batchData,
  publishedDateTime,
  uploadingBatchIndex,
  invalidBatchIndexes,
  isUploading,
  invalidTypeAdd,
  setShowFileList,
  batchLoaders,
  editingAddIndexes,
  setUploadingFile,
  fileProgress,
  handleFileChange1,
  UploadStatus,
  batchProgress,
  handleDrop1,
  handleAddToBatch,
  draggedBatchIndex,
  replacedFiles,
  cancelUpload,
  handleCancelEditFileName1,
  editedFileNames,
  batches,
  handleDeleteFile1,
  handleEditFileName1,
  setCancelUpload,
  setBatches,
  editedAddFileNames,
  currentBatchIndex,
  handleSaveFileName1,
  setCurrentBatchIndex,
  handleFileClick,
  handleUndoDelete1,
  handleAddMoreDragLeave,
  handleAddMoreDragEnter,
  setEditedAddFileNames,
  handleRetry
}) => {
  // function handleRetry(fileIndex: number): void {
  //   throw new Error("Function not implemented.");
  //   console.log("Retry file upload", fileIndex);
  // }

  return (
    <Accordion
      defaultExpanded={true}
      sx={{
        "&.MuiPaper-root": {
          padding: "0px",
          border: "none",
          marginBottom: "0px !important",
        },
        "&.MuiPaper-root .MuiButtonBase-root": {
          padding: "0px",
          height: "auto",
          minHeight: "auto",
          background: "transparent",
        },
        "&.MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content": {
          margin: "0px",
          flexDirection: "column",
        },
        "&.MuiPaper-root .MuiAccordionDetails-root": {
          padding: "0px",
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          <Icons name="chevronDown" fill="#8E8D8A" width={24} height={24} />
        }
        aria-controls={`panel${index + 1}-content`}
        id={`panel${index + 1}-header`}
      >
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "500",
            color: "#333",
            display: "flex",
            alignItems: "center",
          }}
        >
          Batch {index + 1}
          {uploadedStatus[index] && (
            <Icons
              name="checkCircle"
              fill="#007634"
              width={16}
              height={16}
              style={{
                marginLeft: "6px",
                marginTop: "-2px",
              }}
            />
          )}
        </Typography>
        {Object.keys(uploadSpeed).length>0 && 
         uploadSpeed.bytes!==0 &&(<div>
         <Typography variant="caption" sx={{color:'#FFA737'}}>{(uploadSpeed.uploadSpeed/(1024*1024)).toFixed(2)} mbps - {uploadSpeed.bytes.toFixed(2)} of {uploadSpeed.totalBytes.toFixed(2)} mb,  {getTimeFormat(uploadSpeed.timeRemaining)} </Typography>
       </div>)
        }
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            color: "#333",
            display: "flex",
          }}
        >
          {batchData.files.length} Files{" "}
          {uploadedStatus[index] ? "uploaded" : "uploading"}
          <Typography
            sx={{
              marginLeft: "12px",
              fontSize: "14px",
              fontWeight: "400",
              color: "#696969",
            }}
          >
            {publishedDateTime && publishedDateTime[index]
              ? `${publishedDateTime[index]}`
              : "Publish immediately"}
          </Typography>
        </Typography>
        <Typography>
          {uploadingBatchIndex === index && isUploading && (
            <LinearProgress
              variant="determinate"
              value={batchProgress[index]}
              sx={{
                width: "100%",
                marginTop: "5px",
                height: "6px",
                borderRadius: "10px",
                background: "#EFEFEF",
                "& .MuiLinearProgress-bar": {
                  backgroundImage:
                    "linear-gradient(135deg, #409BFF 25%, #007AFF 25%, #007AFF 50%, #409BFF 50%, #409BFF 75%, #007AFF 75%, #007AFF)",
                  backgroundSize: "10px 10px",
                  borderRadius: "10px",
                },
              }}
            />
          )}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {cancelUpload.isOpen && cancelUpload.batchIndex === index ? (
          <Box
            sx={{
              background: "#F4F6FF",
              borderRadius: "8px",
              height: "330px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: "8px",
            }}
          >
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: "600",
                marginBottom: "8px",
              }}
            >
              Are you sure you want to cancel?
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              There are {batches[index].files.length} files remaining{" "}
            </Typography>
            <Box
              sx={{
                marginTop: "32px",
              }}
            >
              <Link
                onClick={() => {
                  setCancelUpload({
                    isOpen: false,
                    batchIndex: index,
                  });
                }}
                underline="none"
                sx={{
                  paddingX: "20px",
                  borderRadius: "8px",
                  background: "#fff",
                  border: "1px solid #FFA737",
                  marginRight: "12px",
                  height: "44px",
                }}
                component={"button"}
              >
                <Typography color={"#333"} variant="cbutton">
                  Continue uploading
                </Typography>
              </Link>
              <Link
                onClick={() => {
                  setCancelUpload({
                    isOpen: false,
                    batchIndex: index,
                  });
                  setBatches((prevBatches) => {
                    const newBatches = prevBatches.filter(
                      (_, i) => i !== index,
                    );

                    if (currentBatchIndex >= 1) {
                      setCurrentBatchIndex(Math.max(0, newBatches.length - 1));
                    } else {
                      const updatedBatch = {
                        files: [],
                        isPublished: false,
                        filesAdd: [],
                        addedToBatch: false,
                        uploadStatus: [UploadStatus.WAITING],
                      };
                      newBatches[currentBatchIndex] = updatedBatch;
                      setUploadingFile(false);
                      setShowFileList(false);
                    }

                    return newBatches;
                  });
                }}
                underline="none"
                sx={{
                  paddingX: "20px",
                  bgcolor: "#FFA737",
                  borderRadius: "8px",
                  height: "44px",
                }}
                component={"button"}
              >
                <Typography color={"#333"} variant="cbutton">
                  Confirm cancel
                </Typography>
              </Link>
            </Box>
          </Box>
        ) : (
          <>
            <Box sx={{ marginTop: "10px" }}>
              {batchData.files.map((file, fileIndex) => (
                <Box
                  key={fileIndex}
                  sx={{
                    borderBottom: "1px solid #EFEFEF",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      paddingY: "8px",
                      fontWeight: "400",
                      color: "#333",
                      display: "flex",
                      alignItems: "center",
                      maxWidth:"300px"

                    }}
                  >
                    {" "}
                    <Icons
                      name={batchData && batchData.files[fileIndex]?.type?.includes("pdf") ? "textFile" : "recordingShow"}
                      fill="#8E8D8A"
                      width={18}
                      height={18}
                      style={{
                        marginRight: "8px",
                        opacity: file.deleted ? "0.5" : "1",
                      }}
                    />{" "}
                    <Box
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#333",
                        opacity: file.deleted ? "0.5" : "1",
                        width: "350px",
                        textWrap: "wrap",
                      }}
                    >
                      <>
                        {editedFileNames[index] &&
                          editedFileNames[index][fileIndex] &&
                          editedFileNames[index][fileIndex]?.edited !==
                            undefined && (
                            <Typography sx={{ marginBottom: "-7px" }}>
                              {editedFileNames[index][fileIndex]?.edited}
                            </Typography>
                          )}
                        <Typography
                          sx={{
                            textDecoration:
                              editedFileNames[index] &&
                              editedFileNames[index][fileIndex]
                                ? "underline"
                                : "",
                            opacity:
                              editedFileNames[index] &&
                              editedFileNames[index][fileIndex]
                                ? "0.5"
                                : "1",
                          }}
                        >
                          {editedFileNames[index]?.[fileIndex]?.original ||
                            (batchData.files &&
                              batchData.files[fileIndex]?.name)}
                        </Typography>
                      </>
                    </Box>
                  </Typography>

                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {replacedFiles[fileIndex] && (
                      <Typography
                        sx={{
                          borderRadius: "4px",
                          fontSize: "12px",
                          fontWeight: "500",
                          paddingLeft: "4px",
                          paddingRight: "8px",
                          background: "#EBECF1",
                          display: "flex",
                          alignItems: "center",
                          marginRight: "8px",
                          height: "24px",
                        }}
                      >
                        <Icons
                          name="replace"
                          fill="#3B4478"
                          width={16}
                          height={16}
                          style={{
                            marginRight: "4px",
                          }}
                        />
                        New version
                      </Typography>
                    )}
                    {
                    batchData.uploadStatus &&
                    batchData.uploadStatus[fileIndex] ===
                      UploadStatus.COMPLETED ? (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#007634",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Icons
                          name="checkCircle"
                          fill="#007634"
                          width={16}
                          height={16}
                          style={{
                            marginRight: "4px",
                          }}
                        />
                        File uploaded
                      </Typography>
                    ) :  batchData.uploadStatus &&
                    batchData.uploadStatus[fileIndex] ===
                      UploadStatus.FAILED?
                    (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#FF0000",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Icons
                          name="cancel"
                          fill="#FF0000"
                          width={16}
                          height={16}
                          style={{
                            marginRight: "4px",
                          }}
                        />
                        Failed
                        <Button
                            onClick={() => handleRetry(fileIndex,currentBatchIndex)}
                            sx={{
                              marginLeft: "8px",
                              fontSize: "14px",
                              fontWeight: "700",
                              color: "#FFA737",
                              textTransform: "capitalize",
                        '&:hover': {
                          backgroundColor: "#FFE4B3",
                          color: "#FF8C00",  
                        },
                              
                            }}
                          >
                            Retry
                          </Button>
                      </Typography>
                    ):(
                      <Typography
                        sx={{
                          width:
                            batchData.uploadStatus &&
                            batchData.uploadStatus[fileIndex] ===
                              UploadStatus.WAITING
                              ? " 70px"
                              : "130px",
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#696969",
                        }}
                      >
                        {batchData.uploadStatus[fileIndex]}
                        <Typography>
                          {uploadingBatchIndex === index &&
                            fileProgress[fileIndex] !== 100 && (
                              <LinearProgress
                                variant="determinate"
                                value={fileProgress[fileIndex]}
                                sx={{
                                  width: "100%",
                                  marginTop: "0px",
                                  height: "6px",
                                  borderRadius: "10px",
                                  background: "#EFEFEF",
                                  "& .MuiLinearProgress-bar": {
                                    backgroundImage:
                                      "linear-gradient(135deg, #409BFF 25%, #007AFF 25%, #007AFF 50%, #409BFF 50%, #409BFF 75%, #007AFF 75%, #007AFF)",
                                    backgroundSize: "10px 10px",
                                    borderRadius: "10px",
                                  },
                                }}
                              />
                            )}
                        </Typography>
                      </Typography>
                    )}
                  </Typography>
                </Box>
              ))}
            </Box>
            {!uploadedStatus[index] && (
              <Link
                onClick={() => {
                  setCancelUpload({
                    isOpen: true,
                    batchIndex: index,
                  });
                }}
                underline="none"
                sx={{
                  bgcolor: "#fff",
                  borderBottom: "2px solid #FFA737",
                  height: "24px",
                  marginTop: "8px",
                }}
                component={"button"}
              >
                <Typography color={"#333"} variant="cbutton">
                  Cancel upload
                </Typography>
              </Link>
            )}
            {batchData.filesAdd && batchData.filesAdd.length > 0 ? (
              <Box
                sx={{
                  borderRadius: "8px",
                  border: "1px solid #D2D2D0",
                  padding: "12px",
                  marginTop: "16px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#333",
                  }}
                >
                  {batchData.filesAdd && batchData.filesAdd.length} New files
                  for upload
                </Typography>
                <Box
                  sx={{
                    maxHeight: "128px",
                    overflowY: "scroll",
                  }}
                >
                  {batchData.filesAdd &&
                    batchData.filesAdd.map((file, indexx) => (
                      <Box
                        key={indexx}
                        sx={{
                          borderBottom: "1px solid #EFEFEF",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            paddingY: "8px",
                            fontWeight: "400",
                            color: "#333",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <Icons
                            name="textFile"
                            fill="#8E8D8A"
                            width={18}
                            height={18}
                            style={{
                              marginRight: "8px",
                              opacity:
                                batchData.filesAdd &&
                                batchData.filesAdd[indexx]?.deleted
                                  ? "0.5"
                                  : "1",
                            }}
                          />{" "}
                          {editingAddIndexes[index] === indexx ? (
                            <TextField
                              id="editFile"
                              variant="outlined"
                              value={
                                editedAddFileNames[index]?.[indexx]?.edited !==
                                undefined
                                  ? editedAddFileNames[index]?.[indexx]?.edited
                                  : batchData.filesAdd &&
                                    batchData.filesAdd[indexx]?.name
                              }
                              onChange={(e) => {
                                const editedValue = e.target.value;
                                setEditedAddFileNames((prev) => ({
                                  ...prev,
                                  [index]: {
                                    ...(prev[index] || {}),
                                    [indexx]: {
                                      edited: editedValue,
                                      original:
                                        editedValue !== "" &&
                                        batchData.filesAdd &&
                                        batchData.filesAdd[indexx]
                                          ? batchData.filesAdd[indexx].name
                                          : "",
                                    },
                                  },
                                }));
                              }}
                              sx={{
                                ".Mui-focused": {
                                  background: "#FFF8EC",
                                  ".css-9sors8-MuiButtonBase-root-MuiIconButton-root:hover":
                                    {
                                      background: "transparent",
                                    },
                                  ".css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover":
                                    {
                                      background: "transparent",
                                    },
                                  ".css-14sxf79-MuiInputBase-input-MuiOutlinedInput-input":
                                    {
                                      color: "#333",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    },
                                },
                                ".MuiInputBase-root": {
                                  width: "385px",
                                  maxHeight: "44px",
                                  borderRadius: "8px",
                                  "&:focus-visible": {
                                    outline: "none !important",
                                  },
                                },
                                "&.MuiFormControl-root .MuiInputBase-root .css-19z3763-MuiInputBase-input-MuiOutlinedInput-input":
                                  {
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#333",
                                    padding: "12px 16px",
                                    textTransform: "capitalize",
                                  },
                                "&.MuiFormControl-root .MuiInputBase-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
                                  {
                                    border: "1px solid #FFA737",
                                  },
                                ".css-9sors8-MuiButtonBase-root-MuiIconButton-root":
                                  {
                                    padding: "0px",
                                    marginLeft: "-7px",
                                    pointerEvents: "none",
                                  },
                              }}
                            />
                          ) : (
                            <Box
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#333",
                                opacity:
                                  batchData.filesAdd &&
                                  batchData.filesAdd[indexx]?.deleted
                                    ? "0.5"
                                    : "1",
                              }}
                            >
                              <>
                                {editedAddFileNames[index] &&
                                  editedAddFileNames[index][indexx] &&
                                  editedAddFileNames[index][indexx]?.edited !==
                                    undefined && (
                                    <Typography>
                                      {
                                        editedAddFileNames[index][indexx]
                                          ?.edited
                                      }
                                    </Typography>
                                  )}
                                <Typography
                                  sx={{
                                    textDecoration:
                                      editedAddFileNames[index] &&
                                      editedAddFileNames[index][indexx]
                                        ? "line-through"
                                        : "",
                                    opacity:
                                      editedAddFileNames[index] &&
                                      editedAddFileNames[index][indexx]
                                        ? "0.5"
                                        : "1",
                                  }}
                                >
                                  {editedAddFileNames[index]?.[indexx]
                                    ?.original ||
                                    (batchData.filesAdd &&
                                      batchData.filesAdd[indexx]?.name)}
                                </Typography>
                              </>
                              {replacedFiles[index] ? (
                                <Typography sx={{ color: "red" }}>
                                  File exists
                                </Typography>
                              ) : null}
                            </Box>
                          )}
                        </Typography>

                        {editingAddIndexes[index] === indexx ? (
                          <Link
                            onClick={() => handleSaveFileName1(index, indexx)}
                            underline="none"
                            sx={{
                              paddingX: "20px",
                              bgcolor: "#FFA737",
                              borderRadius: "8px",
                              height: "44px",
                            }}
                            component={"button"}
                          >
                            <Typography color={"#333"} variant="cbutton">
                              Save
                            </Typography>
                          </Link>
                        ) : (
                          <>
                            {batchData.filesAdd &&
                              batchData.filesAdd[indexx] &&
                              !batchData.filesAdd[indexx]?.deleted && (
                                <Typography
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Icons
                                      name="edit"
                                      fill="#8E8D8A"
                                      width={20}
                                      height={20}
                                      style={{
                                        marginRight: "15px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleEditFileName1(index, indexx)
                                      }
                                    />
                                    <Icons
                                      name="trash"
                                      fill="#8E8D8A"
                                      width={20}
                                      height={20}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleDeleteFile1(index, indexx)
                                      }
                                    />
                                  </Typography>
                                </Typography>
                              )}
                          </>
                        )}

                        {batchData.filesAdd &&
                          batchData.filesAdd[indexx]?.deleted && (
                            <Typography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Icons
                                name="undo"
                                fill="#3B4478"
                                width={16}
                                height={16}
                              />
                              <Link
                                onClick={() => handleUndoDelete1(index, indexx)}
                                underline="none"
                                sx={{
                                  borderBottom: "2px solid #FFA737",
                                  height: "24px",
                                  alignSelf: "center",
                                }}
                                component={"button"}
                              >
                                <Typography color={"#333"} variant="cbutton">
                                  Undo
                                </Typography>
                              </Link>
                            </Typography>
                          )}
                      </Box>
                    ))}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "12px",
                  }}
                >
                  <Box>
                    <Link
                      onClick={() => {
                        handleCancelEditFileName1(index);
                        setEditedAddFileNames((prev) => {
                          const updatedFileNames = { ...prev };

                          if (updatedFileNames[index]) {
                            updatedFileNames[index] = {};
                          }
                          return updatedFileNames;
                        });
                      }}
                      underline="none"
                      sx={{
                        paddingX: "20px",
                        borderRadius: "8px",
                        border: "1px solid #FFA737",
                        marginRight: "12px",
                        height: "44px",
                      }}
                      component={"button"}
                    >
                      <Typography color={"#333"} variant="cbutton">
                        Cancel
                      </Typography>
                    </Link>
                    <Link
                      onClick={() => handleAddToBatch(index)}
                      underline="none"
                      sx={{
                        paddingX: "20px",
                        bgcolor: "#FFA737",
                        borderRadius: "8px",
                        height: "44px",
                        opacity: batchData.filesAdd.every(
                          (file) => file.deleted,
                        )
                          ? "0.5"
                          : "1",
                      }}
                      component={"button"}
                      disabled={batchData.filesAdd.every(
                        (file) => file.deleted,
                      )}
                    >
                      <Typography color={"#333"} variant="cbutton">
                        Add to batch
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>
            ) : (
              <>
                {!uploadedStatus[index] && (
                  <>
                    {!batchLoaders[index] ? (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            marginTop: "16px",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "2px dashed #D2D2D0",
                            borderRadius: "8px",
                            padding: "22px 0px",
                            cursor: "pointer",
                            backgroundColor:
                              (draggedBatchIndex !== undefined &&
                                draggedBatchIndex === index) ||
                              batchLoaders[index]
                                ? "#007AFF"
                                : "#fff",
                            "&:hover": {
                              backgroundColor:
                                (draggedBatchIndex !== undefined &&
                                  draggedBatchIndex === index) ||
                                batchLoaders[index]
                                  ? "#007AFF"
                                  : "#ffa7371f",
                            },
                          }}
                          onDragEnter={(e) => handleAddMoreDragEnter(e, index)}
                          onDragLeave={handleAddMoreDragLeave}
                          onDragOver={(e)=>e.preventDefault()}
                          onDrop={(e) => handleDrop1(e, index)}
                          onClick={()=>handleFileClick(`fileInputAddToBatch${index}`)}
                        >
                          <input
                            type="file"
                            id={`fileInputAddToBatch${index}`}
                            style={{ display: "none" }}
                            onChange={handleFileChange1}
                          />
                          <>
                            <img
                              style={{ marginBottom: "5px" }}
                              src={UploadImg}
                              alt=""
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "600",
                                color: "#333",
                              }}
                            >
                              Add more files
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#696969",
                              }}
                            >
                              Click to browse, or drag & drop a file here
                            </Typography>
                          </>
                        </Box>
                        {invalidBatchIndexes.includes(index) &&
                          invalidTypeAdd && (
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "500",
                                textAlign: "center",
                                color: "red",
                                marginTop: "2px",
                              }}
                            >
                              Invalid file type. Please upload only PDF and MP4
                              files.
                            </Typography>
                          )}
                      </>
                    ) : (
                      <CircularProgressScreen
                        showLoader={batchLoaders[index]}
                        uploadProgress={uploadProgress}
                        addFileTag={true}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default UploadAccordion;
