import React, { useState, useEffect } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import {
  getNavItems,
} from "../../services/api/roles";
import Cookies from "js-cookie";

interface Subcategory {
  nav_id: number;
  menu_name: string;
  is_primary_nav: number | null;
  nav_menu_id: number | null;
  nav_status: number;
  createdAt: string;
  updatedAt: string;
}

interface Category {
  nav_id: number;
  menu_name: string;
  is_primary_nav: number | null;
  nav_menu_id: number | null;
  nav_status: number;
  createdAt: string;
  updatedAt: string;
  children: Subcategory[];
}

const verifyAccessString = Cookies.get("authAccess");
const verifyAccess = verifyAccessString ? JSON.parse(verifyAccessString) : null;
interface CategoriesProps {
  onRoleChange: (value: string) => void;
  onPermissionsChange: (permissions: { nav_id: number }[]) => void;
}
// const Categories = () => {
  const Categories: React.FC<CategoriesProps> = ({
    onRoleChange,
    onPermissionsChange,
  }) => {
    const [inputValue, setInputValue] = useState("");
  const [selectedPermissions, setSelectedPermissions] = useState<
    { nav_id: number }[]
  >([]);

  const [getNav, setNav] = useState([]);

  // const fetchAddUserPermissionData = async (payload: any) => {
  //   try {
  //     const response = await getAddUserPermission(payload, verifyAccess.token);
  //     // setNav(response?.data?.data);
  //     console.log("-->response<--", response); // ✅ Now runs only once on mount
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  useEffect(() => {
    const fetchNavData = async () => {
      try {
        const response = await getNavItems(verifyAccess.token);
        setNav(response?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchNavData();
  }, []);

  const categoriesData: Category[] = getNav;

  // State to keep track of selected categories/subcategories
  const [selectedCategories, setSelectedCategories] = useState<Set<number>>(
    new Set()
  );


  // Handle main category selection
const handleCategoryChange = (category: Category, checked: boolean) => {
  let updatedPermissions;
  
  if (checked) {
    // Add the new category to permissions list
    updatedPermissions = [...selectedPermissions, { nav_id: category.nav_id }];
  } else {
    // Remove the category and all its subcategories from permissions
    updatedPermissions = selectedPermissions.filter(
      (perm) => perm.nav_id !== category.nav_id && 
                !category.children.some((sub) => sub.nav_id === perm.nav_id)
    );
  }

  setSelectedPermissions(updatedPermissions);
  onPermissionsChange(updatedPermissions); // Ensure onPermissionsChange is updated here

  console.log("Category Selected:", category.nav_id, "Checked:", checked);

  setSelectedCategories((prevSelected) => {
    const updatedSelected = new Set(prevSelected);
    if (checked) {
      updatedSelected.add(category.nav_id);
    } else {
      updatedSelected.delete(category.nav_id);
    }
    return updatedSelected;
  });

  return checked;
};

// Handle subcategory selection
const handleSubcategoryChange = (subcategoryId: number, checked: boolean, parentCategoryId: number, category: Category) => {
  setSelectedPermissions((prevPermissions) => {
    let updatedPermissions = [...prevPermissions];

    if (checked) {
      // Add subcategory to permissions list
      updatedPermissions.push({ nav_id: subcategoryId });

      // Ensure the parent category is also selected
      if (!selectedCategories.has(parentCategoryId)) {
        updatedPermissions.push({ nav_id: parentCategoryId });
        setSelectedCategories((prevSelected) => new Set(prevSelected).add(parentCategoryId));
      }
    } else {
      // Remove subcategory from permissions list
      updatedPermissions = prevPermissions.filter((perm) => perm.nav_id !== subcategoryId);
      
      // Check if there are any remaining subcategories selected under the same parent
      const hasOtherSelectedSubcategories = prevPermissions.some(
        (perm) => category.children.some((sub: { nav_id: number; }) => sub.nav_id === perm.nav_id) && perm.nav_id !== subcategoryId
      );

      // If no other subcategories are checked, remove the parent category too
      if (!hasOtherSelectedSubcategories) {
        updatedPermissions = updatedPermissions.filter((perm) => perm.nav_id !== parentCategoryId);
      }
    }

    onPermissionsChange(updatedPermissions); // Ensure onPermissionsChange is updated here
    console.log("Subcategory Selected:", subcategoryId, "Checked:", checked);

    return updatedPermissions;
  });

  return checked;
};


  // const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setInputValue(event.target.value);
    const value = event.target.value;
    setInputValue(value);
    onRoleChange(value);
  };

  return (
    <div>
      <TextField
        placeholder="Role"
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        style={{ marginBottom: 20 }}
      />
      <FormGroup>
        {categoriesData.map((category) => (
          <div key={category.nav_id}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedCategories.has(category.nav_id)}
                  onChange={(e) =>
                    handleCategoryChange(category, e.target.checked)
                  }
                />
              }
              label={category.menu_name}
            />
            {category.children && category.children.length > 0 && (
              <div style={{ paddingLeft: 20 }}>
                {category.children.map((subcategory) => (
                  <FormControlLabel
                    key={subcategory.nav_id}
                    control={
                      <Checkbox
                        checked={selectedPermissions.some(
                          (perm) => perm.nav_id === subcategory.nav_id
                        )}
                        onChange={(e) =>
                          handleSubcategoryChange(
                            subcategory.nav_id,
                            e.target.checked,
                            category.nav_id,
                            category
                          )
                        }
                      />
                    }
                    label={subcategory.menu_name}
                  />
                ))}
              </div>
            )}
          </div>
        ))}
      </FormGroup>
    </div>
  );
};

export default Categories;