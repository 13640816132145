import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, Typography } from "@mui/material";
import Icons from "../Icons";
import GlobalPopupModal from "../Popup/dialog";
import SubjectDetailsModal from "../Subject/subjectDetailsModal";
import {
  closeSubject,
  deletSubject,
  openSubject,
} from "../../services/api/subjects";
import { useAppSelector } from "../../store/hooks";
import ToastMessage from "../ToastMessage";

interface EditSub {
  id?: number;
  mst_subject_group_id?: number;
  subject?: string;
  subject_description?: string | null;
  aos_id?: string | null;
  area_of_study?: string | null;
  area_of_study_description?: string | null;
  subject_icon?: string | null;
  subject_colour?: string;
  updated_by?: string | null;
  record_status?: string;
  createdAt?: string;
  updatedAt?: string;
  sub_code?: string;
}

type GetSub = {
  subject_group_id?: number;
  subject_group?: number;
  subject_id?: number;
  area_of_id?: number;
  topic_id?: number;
  type_id?: number;
  file_type_id?: number;
  search_key?: string;
};
interface subProps {
  children: React.ReactNode;
  boxSx: object;
  clicked: boolean;
  DescriptionIcon: boolean;
  closed: boolean;
  iconOpacity?: boolean;
  fetchEditSubjectData?: (id: number) => Promise<void>;
  subId?: number;
  subName?: string;
  editSubData?: EditSub;
  handeleSetSubject?: () => void;
  setSeleTabData?: React.Dispatch<React.SetStateAction<GetSub>>;
}

const SubjectListt = ({
  children,
  boxSx,
  clicked,
  iconOpacity,
  DescriptionIcon,
  closed,
  fetchEditSubjectData,
  subId,
  subName,
  editSubData,
  setSeleTabData,
  handeleSetSubject,
}: subProps) => {
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  console.log("2", editSubData);
  //Changes on 10/09/2021 - start
    const [canClickGrid, setCanClickGrid] = useState(true); 
    const [isEditCourseDetailsModalOpen, setEditCoursetDetailsModalOpen] =
    useState(false);
      const [isSubjectDetailsModalOpen, setIsSubjectDetailsModalOpen] = useState(false);
        const auth = useAppSelector((state) => state.auth);

  const [isHovering, setIsHovering] = React.useState(false);
  const [toast, setToast] = React.useState<{
    type: string;
    message: string;
  } | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!canClickGrid) {
      // Prevent opening the grid if modal is open or grid is disabled
      event.stopPropagation();
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  //Changes on 10/09/2021 - end

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
    setAnchorEl(null);
  };


  const handleConfirm = async () => {
    const id =
      subId !== undefined ? subId.toString() : (undefined as unknown as string);
    await closeSubject(id, auth?.token)
      .then((response) => {
        handeleSetSubject && handeleSetSubject();
        setSeleTabData &&
          setSeleTabData((prevFilters) => {
            return {
              subject_group: prevFilters.subject_group,
            };
          });
        setToast({
          type: "success",
          message: response.data.message,
        });
      })
      .catch((err) => {
        setToast({
          type: "error",
          message: err.response.data.error,
        });
      });

  closeModal(); // Reset modal state after closing //Changes on 10/09/2021 
  };

  const handleOpen = async () => {
    
    const id =
      subId !== undefined ? subId.toString() : (undefined as unknown as string);
    await openSubject(id, auth?.token)
      .then((response) => {
        handeleSetSubject && handeleSetSubject();
        setSeleTabData &&
          setSeleTabData((prevFilters) => {
            return {
              subject_group: prevFilters.subject_group,
            };
          });
        setToast({
          type: "success",
          message: response.data.message,
        });
      })
      .catch((err) => {
        setToast({
          type: "error",
          message: err.response.data.error,
        });
      });

      closeModal(); // Reset modal state after closing //Changes on 10/09/2021 
  };

  
 const handleEditSubject = (
    val: number,
    event: React.MouseEvent<HTMLLIElement>
  ) => {
    event.stopPropagation(); // Prevent event propagation
    fetchEditSubjectData && fetchEditSubjectData(val);
    setEditCoursetDetailsModalOpen(true);
    setCanClickGrid(false); // Disable grid click while modal is open
  };

  //Changes on 10/09/2021 - removed
  // const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
        }
        : 
        null,
    );
  };

  const handleClose = async (val: string) => {
    setContextMenu(null);
    setAnchorEl(null);
    console.log(val);

    if (val === "Close" || val === "Open") {
      setIsSubjectDetailsModalOpen(true);
      setCanClickGrid(false); // Disable grid click while modal is open
    }
    if (val == "delete") {
      const id =
        subId !== undefined
          ? subId.toString()
          : (undefined as unknown as string);
      await deletSubject(id, auth?.token)
        .then((response) => {
          console.log(response);
          handeleSetSubject && handeleSetSubject();
          setSeleTabData &&
            setSeleTabData((prevFilters) => {
              return {
                subject_group: prevFilters.subject_group,
              };
            });
          setToast({
            type: "success",
            message: response.data.message,
          });
        })
        .catch((err) => {
          setToast({
            type: "error",
            message: err.response.data.error,
          });
        });
    }
  };

  //Changes on 10/09/2021 - start
  const closeModal = () => {
    setIsSubjectDetailsModalOpen(false); // Close the modal
    setCanClickGrid(true); // Allow grid to be clicked again after modal is closed
  };
  const handleCloseModal = () => {
    setEditCoursetDetailsModalOpen(false);
    setCanClickGrid(true); 
  };
  //Changes on 10/09/2021 - end

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      gap={"8px"}
      alignItems={"center"}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      aria-describedby="simple-popover"
      onContextMenu={handleContextMenu}
      style={{ cursor: "context-menu" }}
      component={"button"}
      onClick={handleClick}
      sx={{ padding: "12px", width: "100%", ...boxSx }}
    >
      <Box flex={1}>{children}</Box>
      <Box
        display={"flex"}
        gap={"4px"}
        flexDirection={"row"}
        alignItems={"center"}
      >
        <Box
          sx={{
            paddingX: "4px",
            paddingY: "4px",
            borderRadius: "4px",
            bgcolor: "#F4F6FF",
            lineHeight: 0,
            border: 0,
          }}
          component={"button"}
        >
          <Box
            sx={{
              opacity: !iconOpacity ? 1 : 0.3,
              lineHeight: 0,
            }}
          >
            <Icons name="galleryIcon" fill="#3B4478" width={16} height={16} />
          </Box>
        </Box>

        <Box
          sx={{
            paddingX: "4px",
            paddingY: "4px",
            borderRadius: "4px",
            bgcolor: "#F4F6FF",
            lineHeight: 0,
            border: 0,
          }}
          component={"button"}
        >
          <Box
            sx={{
              opacity: !DescriptionIcon ? 1 : 0.3,
              lineHeight: 0,
            }}
          >
            <Icons name="description" fill="#3B4478" width={16} height={16} />
          </Box>
        </Box>
      </Box>
      {isHovering || anchorEl || clicked ? (
        <Icons name="chevronRight" fill="#333333" />
      ) : (
        <Icons name="chevronRight" fill="#8E8D8A" />
      )}
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "8px",
            minWidth: "135px",

            boxShadow: "0px 4px 20px 0px rgba(45, 45, 45, 0.16)",
            "& .MuiMenu-list": {
              padding: "0px",
            },
          },
        }}
      >
        <MenuItem
          sx={{
            gap: "8px",
            padding: "12px",
            alignItems: "center",
            flexDirection: "row",
            borderBottom: "1px solid #D2D2D0",
          }}
          onClick={(event) => {
            handleClose("Edit");
              subId && handleEditSubject(subId, event);
          }}
        >
          <Icons name="edit" fill="#8E8D8A" width={20} height={20} />
          <Typography color={"#333"} variant="bodySm">
            Edit
          </Typography>
        </MenuItem>
      </Menu>
      <GlobalPopupModal
        titleName={closed ? "Open subject" : "Close subject"}
        open={isSubjectDetailsModalOpen}
        btnText={closed ? "Confirm open" : "Confirm close"}
        handleCloseModal={handleCloseModal}
        handleFooterCancle={handleCloseModal}
        handleFooterBtn={closed ? handleOpen : handleConfirm}
        modalWidth={"396px"}
      >
        <Typography color="contour.title3" variant="title4">
          Are you sure you want to {closed ? "open" : "close"} {subName} ?
        </Typography>
        <Typography
          color="contour.inactive"
          variant="bodyLg"
          sx={{ fontWeight: "400", display: "block", marginTop: "8px" }}
        >
          All subsequent folders will become{" "}
          {closed ? "available" : "unavailable"} in the student portal.
        </Typography>
      </GlobalPopupModal>
      <SubjectDetailsModal
        open={isEditCourseDetailsModalOpen}
        handleCloseModal={handleCloseModal} 
        titleName={"Edit area of study"}
        name={"Area of study name"}
        setSeleTabData={setSeleTabData}
        description={"Area of study description"}
        iconName={"Subject icon"}
        type={"edit aos"}
        editSubData={editSubData}
        setToast={setToast}
        AosCodee={'Area of study code'}
        isAosModalOpen={true}
      />
      {toast && (
        <ToastMessage
          type={toast.type as "success" | "error"}
          message={toast.message}
          onClose={() => setToast(null)}
          style={{
            position: "absolute",
            top: "5%",
          }}
        />
      )}
    </Box>
  );
};

export default SubjectListt;
