
// import axios from "axios";
import client from "./client";
 

interface addUserPayload {
   
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    role_id: string;
    added_by:string;
    // is_verified:string;
    // added_by:string;
  }

export const getUserList = async (token: string,page:number,limit:number) =>
    await client.get(`/employees/get-all-employees?page=${page}&limit=${limit}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
 

    export const addUserApi = async (payload: addUserPayload, token: string) =>
        await client.post("/employees/add-employee", payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        