import { useState } from "react";
import Dashboard from "../../layouts/Dashboard";
import { Box, Typography } from "@mui/material";
import { RolesComponent } from "../../components/Roles";
import GlobalPopupModal from "../../components/Popup/dialog";
import AddNewStudentComponent from "../../components/Users/AddNewSubjectComponent";
import { addUserApi } from "../../services/api/user";
import Cookies from "js-cookie";
import ToastMessage from "../../components/ToastMessage";
import TransparentBdrBtn from "../../components/Button/TranparentBdrBtn";
import Categories from "../../components/Roles/categories";
import {getAddUserPermission,} from "../../services/api/roles";

// Define the structure of the subject payload

// Define the structure of the student payload
interface addStuPayload {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  role_id: string;
  password: string;
  added_by: string;
}

export const Roles = () => {
  const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;
  const [isStudentModalOpen, setIsStudentModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // Initial student data with default values
  const initialStudentData: addStuPayload = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    role_id: "",
    password: "",
    added_by: "",
  };

  const [toast, setToast] = useState<{ type: string; message: string } | null>(
    null
  );

  const [studentData, setStudentData] = useState(initialStudentData);
  console.log("userdata------", studentData);
  // Disable the button if any required field is empty, including campus_id as number
  const isCreateButtonDisabled = !(
    studentData.first_name &&
    studentData.last_name &&
    studentData.phone &&
    studentData.email &&
    studentData.role_id && // Check that campus_id is not empty (number)
    studentData.password
  );

  const updateStudentData = (data: Partial<addStuPayload>) => {
    setStudentData((prevData) => ({ ...prevData, ...data }));
  };

  const handleCloseModal = () => {
    setIsStudentModalOpen(false);
  };



  const handleCreateNewStudent = async () => {
    // Validation: Ensure all fields are filled
    if (
      !studentData.first_name ||
      !studentData.last_name ||
      !studentData.phone ||
      !studentData.email ||
      !studentData.role_id || // Ensure campus_id is not empty (number)
      !studentData.password
    ) {
      console.error("Please fill in all required fields");
      return;
    }

    setLoading(true);
    try {
      const token = verifyAccess?.token; // Use optional chaining to avoid errors if verifyAccess is null
      const response = await addUserApi(studentData, token);
      handleCloseModal();
      const successMessage = response.data.message;

      setToast({
        type: "success",
        message: successMessage,
      });
      window.location.reload();
    } catch (error: any) {
      setToast({
        type: "fail",
        message: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const [isCategoriesModalOpen, setIsCategoriesModalOpen] = useState(false);

  const handleCloseCategoriesModal = () => {
    setIsCategoriesModalOpen(false); // Just close the modal when clicked on the close icon
  };

  const handleAddCategories = () => {
    setIsCategoriesModalOpen(true);
  };

  const handleCreateCategory = () => {
    // Check if any category or subcategory is selected before proceeding
    if (!checkIfAnySelected()) {
      setToast({
        type: "error",
        message: "Please select at least one category or subcategory",
      });
      return;
    }

    console.log("Category created");

    // Simulating category creation

    // Close the modal after successful category creation
    setIsCategoriesModalOpen(false);


    // API Call
    fetchAddUserPermissionData({
      // id: profileData?.roleidint,
      role_name: roleName,
      permissions: permissions,
      
    });

    // window.location.reload();
  };

  // Function to check if at least one category or subcategory is selected
  const checkIfAnySelected = () => {
    return document.querySelectorAll("input[type=checkbox]:checked").length > 0;
  };



  const fetchAddUserPermissionData = async (payload: any) => {
   
    try {
      const response = await getAddUserPermission(payload, verifyAccess.token);
      // setNav(response?.data?.data);
    setToast({ type: "success", message: response.data.message });
       // ✅ Now runs only once on mount
    } catch (error:any) {
      console.error("Error fetching data:", error);
       setToast({ type: "success", message: error?.response?.data?.message });
    }
  };


  const [roleName, setRoleName] = useState("");
  const [permissions, setPermissions] = useState<{ nav_id: number }[]>([]);

  const handleRoleChange = (value: string) => {
    setRoleName(value);
  };

  const handlePermissionsChange = (newPermissions: { nav_id: number }[]) => {
    setPermissions(newPermissions);
  };


  return (
    <Dashboard>
      <Box
        display={"flex"}
        flexDirection={"column"}
        flexGrow={1}
        gap={"24px"}
        paddingRight={"24px"}
      >
        {toast && (
          <ToastMessage
            type={toast.type as "success" | "error"}
            message={toast.message}
            onClose={() => setToast(null)}
            style={{
              position: "absolute",
              top: "90px",
              textTransform: "capitalize",
            }}
          />
        )}
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{ marginRight: "auto" }}
            color={"contour.title2"}
            variant="title2"
            component={"div"}
          >
            Roles
          </Typography>
          <Box display={"flex"} justifyContent={"space-between"}>
          <Box sx={{marginRight: "24px"}}>
              <TransparentBdrBtn
                text="Create Roles"
                textStyle={{ color: "#333"}}
                handleClick={handleAddCategories}
              />
            </Box>
            
            
          </Box>
        </Box>

        {toast && (
          <ToastMessage
            type={toast.type as "success" | "error"}
            message={toast.message}
            onClose={() => setToast(null)}
            style={{
              position: "absolute",
              top: "90px",
              textTransform: "capitalize",
            }}
          />
        )}

        <GlobalPopupModal
          handleFooterBtn={handleCreateCategory}
          btnText="Roles Created"
          open={isCategoriesModalOpen}
          handleCloseModal={handleCloseCategoriesModal}
          modalWidth={"720px"}
          titleName={"Roles"}
        >
          <Categories
          onRoleChange={handleRoleChange}
          onPermissionsChange={handlePermissionsChange}
          />
        </GlobalPopupModal>
        <RolesComponent />
      </Box>

      <GlobalPopupModal
        titleName="New User"
        btnText="Create new user"
        open={isStudentModalOpen}
        handleCloseModal={handleCloseModal}
        loading={loading}
        handleFooterBtn={handleCreateNewStudent}
        modalWidth={"720px"}
        isCreateButtonDisabled={isCreateButtonDisabled}
      >
        <AddNewStudentComponent
          isAddingStudent={true}
          updateStudentData={updateStudentData}
        />
      </GlobalPopupModal>
    </Dashboard>
  );
};
